import React from 'react';
import {Box, DialogTitle, Stack, Typography} from "@mui/material";
import styled from "@emotion/styled";
import PropTypes from 'prop-types';
import TruncatedText from "../TruncatedText";
import IconButton from "@mui/material/IconButton";
import Icon from "../Icon";
import Tooltip from "../Tooltip";

const DescriptionText = styled(Typography)(({ theme }) => ({
  color: theme.colors.gray.secondary,
  width: '100%',
  textOverflow: 'ellipsis',
  wordWrap: 'break-word',
  overflowWrap: 'break-word',
  hyphens: 'auto',
}))

const renderDescription = text => <DescriptionText variant='textMd'>{text}</DescriptionText>

const SingleTitle = ({ children, description, popup, maxDescriptionLength = 100, descriptionTip }) => (
  <Stack>
    <Typography variant={popup ? 'textXl' : 'displaySm'}>{children}</Typography>
    {description && (
      <Stack direction="row" spacing={2} alignItems="flex-start">
        <TruncatedText text={description} renderText={renderDescription} maxLength={maxDescriptionLength} />
        {descriptionTip && (
          <Tooltip arrow title={descriptionTip}>
            <IconButton>
              <Icon size="small" icon='info' />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
    )}
  </Stack>
)

const CustomTitle = ({children}) => (
  <Box>
    {children}
  </Box>
)

const TITLE_MAP = {
  single: SingleTitle,
  custom: CustomTitle,
}

const Title = ({ type, ...props }) => {
  const Component = TITLE_MAP[type] || SingleTitle
  return (
    <DialogTitle>
      <Component {...props} />
    </DialogTitle>
  );
};

Title.propTypes = {
  type: PropTypes.oneOf(Object.keys(TITLE_MAP)).isRequired // Only allows 'single' or 'custom'
};

Title.defaultProps = {
  type: 'single',
}

export default Title;
