import {useCallback, useContext} from "react";
import {ModalContext} from "../contexts/ModalContext";

export const useModal = (modal, { autoClose = true, ...props } = {}) => {
  const { open: openModal, close: closeModal, closeAll } = useContext(ModalContext)
  const closeNow = useCallback(() => closeModal(modal.id), [modal.id])
  const open = useCallback((modalProps = {}) => new Promise((resolve) => {
    const bind = res => {
      if (autoClose) {
        closeNow()
      }
      return resolve(res || true)
    }
    const close = () => {
      closeNow()
      resolve(null)
    }
    openModal({
      ...modal,
      props: {
        ...props,
        modalProps,
        bind,
        close,
      }
    })
  }), [modal.id, props])

  return {
    open,
    closeModal,
    closeAll,
    close: closeNow,
  }
}
