import React, {useState} from 'react';
import {Stack, Typography} from "@mui/material";
import Button from "@mui/material/Button";

const TruncatedText = ({ text, maxLength, renderText }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // Toggle the expanded state
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  const textToShow = isExpanded
    ? text
    : `${text.substring(0, maxLength)}${text.length > maxLength ? '...' : ''}`

  return (
    <Stack spacing={2} sx={{ minWidth: 0 }}>
      {
        renderText
          ? renderText(textToShow)
          : (
            <Typography variant='textMd'>{textToShow}</Typography>
          )
      }
      { text.length > maxLength && (
        <Button variant='text' onClick={toggleExpand}>{isExpanded ? 'Show less' : 'Show More'}</Button>
      )}
    </Stack>
  );
};

export default TruncatedText;