import merge from "deepmerge";
import { green, grey, indigo, red } from "@mui/material/colors";
import { COLORS, THEMES } from "../constants/base";
import { alpha } from "@mui/system";
import { colorPalette } from "./pallete";
import {typographyRedesign} from "./typography";
import componentsRedesign from "./componentsRedesign";

const customBlue = {
  50: "#e9f0fb",
  100: "#c8daf4",
  200: "#a3c1ed",
  300: "#7ea8e5",
  400: "#6395e0",
  500: "#4782da",
  600: "#407ad6",
  700: "#376fd0",
  800: "#2f65cb",
  900: "#2052c2",
};
const negativeColor = '#E5E5E5'

const defaultVariant = {
  name: THEMES.DEFAULT,
  palette: {
    mode: "light",
    primary: {
      main: customBlue[700],
      contrastText: "#FFF",
    },
    text: {
      primary: 'rgba(27, 37, 89, 1)',
      secondary: "#192A3E",
    },
    secondary: {
      main: customBlue[500],
      contrastText: "#FFF",
    },
    background: {
      default: "#F5F6F8",
      paper: "#FFF",
    },
    negative: {
      main: negativeColor,
      contrastText: '#000000',
      light: alpha(negativeColor, 0.5),
      dark: '#d3d0d0'
    },
    widget: {
      label: 'rgba(43, 54, 116, 1)',
    },
    navbar: {
      tab: {
        selected: {
          background: COLORS.whiteSElected,
          color: COLORS.accentBlue,
        },
        background: COLORS.accentBlue,
        color: COLORS.whiteSElected,
      },
      desktop: {
        background: "#F5F6F8",
        color: "#192A3E",
      },
      mobile: {
        background: COLORS.accentBlue,
        color: "#FFF",
      },
    },
    color: {
      lightBlue: "#9ADEFA",
      accentBlue: 'rgba(57, 111, 169, 1)',

      blue: "#04A9F3",
      blueHover: "#34AFF9",
      bluePressed: "#098EDF",

      red: "#F7685B",
      redHover: "#fc8c82",
      redPressed: "#f94e3e",

      yellow: "#FFB946",
      yellowHower: "#ffca75",
      yellowPressed: "#ffaf2e",

      green: "#2ED47A",
      greenHover: "#51e192",
      greenPressed: "#24c66d",

      purple: "#885AF8",
      purpleHower: "#a481fd",
      purplePressed: "#733dfa",

      black: "#192A3E",
      tableBlack: "#323C47",
      tableGray: "#707683",

      darkBlue: "#334D6E",
      gray: "#90A0B7",
      dark_gray: "#757F8C",
      gray_light: "#D6D9E4",
      grayIcon: "#C2CFE0",
      grayPaper: '#E0E5F2',
      darkBlue2: COLORS.darkBlue2,

      white: "#FFFFFF",
      whiteSElected: "#F5F6F8",
      disabled: "#C2CFE0",
    },
  },
  header: {
    color: grey[500],
    background: "#FFF",
    search: {
      color: grey[800],
    },
    indicator: {
      background: customBlue[600],
    },
  },
  footer: {
    color: grey[500],
    background: "#FFF",
  },
  sidebar: {
    color: COLORS.white,
    background: COLORS.accentBlue,
    icon: { color: COLORS.grayIcon },
    active: {
      color: COLORS.white,
      background: COLORS.activeBlue,
    },
    footer: {
      color: "#fff",
      background: "#334D6E",
      online: {
        // background: "#334D6E",
        background: green[500],
      },
    },
    submenu: {
      background: 'rgba(233, 237, 247, 1)',
    }
  },
  snackbar: {
    success: {
      color: "#3d634a",
      backgroundColor: "#e5eece",
    },
    info: {
      color: "#004858",
      backgroundColor: "#AAEFFF",
    },
    error: {
      color: "#773c34",
      backgroundColor: "#ffcece",
    },
    icon: {
      fontSize: 15,
      marginLeft: "7px",
      marginRight: "7px",
    },
  },
};

const darkVariant = merge(defaultVariant, {
  name: THEMES.DARK,
  palette: {
    mode: "dark",
    primary: {
      main: customBlue[600],
      contrastText: "#FFF",
    },
    background: {
      default: "#1B2635",
      paper: "#233044",
    },
    text: {
      primary: "rgba(255, 255, 255, 0.95)",
      secondary: "rgba(255, 255, 255, 0.5)",
    },
  },
  header: {
    color: grey[300],
    background: "#1B2635",
    search: {
      color: grey[200],
    },
  },
  footer: {
    color: grey[300],
    background: "#233044",
  },
});

const lightVariant = merge(defaultVariant, {
  name: THEMES.LIGHT,
  palette: {
    mode: "light",
  },
  header: {
    color: grey[200],
    background: customBlue[800],
    search: {
      color: grey[100],
    },
    indicator: {
      background: red[700],
    },
  },
  sidebar: {
    color: grey[900],
    background: "#FFF",
    header: {
      color: "#FFF",
      background: customBlue[800],
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: grey[800],
      background: "#F7F7F7",
      online: {
        background: green[500],
      },
    },
  },
});

const blueVariant = merge(defaultVariant, {
  name: THEMES.BLUE,
  palette: {
    mode: "light",
  },
  sidebar: {
    color: "#FFF",
    background: customBlue[700],
    header: {
      color: "#FFF",
      background: customBlue[800],
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: "#FFF",
      background: customBlue[800],
      online: {
        background: "#FFF",
      },
    },
    badge: {
      color: "#000",
      background: "#FFF",
    },
  },
});

const greenVariant = merge(defaultVariant, {
  name: THEMES.GREEN,
  palette: {
    primary: {
      main: green[800],
      contrastText: "#FFF",
    },
    secondary: {
      main: green[500],
      contrastText: "#FFF",
    },
  },
  header: {
    indicator: {
      background: green[600],
    },
  },
  sidebar: {
    color: "#FFF",
    background: green[700],
    header: {
      color: "#FFF",
      background: green[800],
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: "#FFF",
      background: green[800],
      online: {
        background: "#FFF",
      },
    },
    badge: {
      color: "#000",
      background: "#FFF",
    },
  },
});

const indigoVariant = merge(defaultVariant, {
  name: THEMES.INDIGO,
  palette: {
    primary: {
      main: indigo[600],
      contrastText: "#FFF",
    },
    secondary: {
      main: indigo[400],
      contrastText: "#FFF",
    },
  },
  header: {
    indicator: {
      background: indigo[600],
    },
  },
  sidebar: {
    color: "#FFF",
    background: indigo[700],
    header: {
      color: "#FFF",
      background: indigo[800],
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: "#FFF",
      background: indigo[800],
      online: {
        background: "#FFF",
      },
    },
    badge: {
      color: "#000",
      background: "#FFF",
    },
  },
});

export const redesignVariant = {
  name: THEMES.REDESIGN,
  palette: {
    mode: "light",
    primary: {
      main: colorPalette.yellow.primary,
      dark: colorPalette.yellow.dark,
      light: colorPalette.yellow.light,
      contrastText: colorPalette.gray["0"],
      disabledBackground: colorPalette.yellow.primary
    },
    secondary: {
      main: colorPalette.gray['0'],
      dark: colorPalette.gray['50'],
      contrastText: colorPalette.gray.primary,
    },
    warning: {
      main: colorPalette.other.tertiary,
      dark: colorPalette.yellow.primary,
      contrastText: colorPalette.yellow.dark,
    },
    error: {
      main: colorPalette.red.primary,
      dark: colorPalette.red.dark,
      light: colorPalette.red.light,
    },
    background: {
      default: colorPalette.gray['0'],
      paper: colorPalette.gray['50'],
    },
  },
  sidebar: {
    color: colorPalette.gray['200'],
    background: colorPalette.gray.primary,
    active: {
      color: colorPalette.gray['0'],
      background: colorPalette.yellow.primary,
    },
  },
  typography: typographyRedesign,
  colors: colorPalette,
  text: {
    primary: colorPalette.gray.primary,
    secondary: colorPalette.gray.secondary
  },
  components: componentsRedesign,
};

const variants = [
  defaultVariant,
  darkVariant,
  lightVariant,
  blueVariant,
  greenVariant,
  indigoVariant,
  redesignVariant,
];

export default variants;
