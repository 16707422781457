import React, {useEffect, useState} from 'react';
import Modal from "../../../../../ui/Modal";
import {Button, IconButton, Paper, Stack} from "@mui/material";
import {Icon} from "../../../../../ui";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import CloseIcon from "@mui/icons-material/Close";
import {useTheme} from "@mui/material/styles";
import {useModal} from "../../../../../hooks/useModal";
import AddEvent from "../../modals/AddEvent";
import {snakeToCamel} from "../../../../../utils/formaters/camelCase";
import {useDeleteEventModal} from "../../hooks/useDeleteEvent";
import Loader from "../../../../../ui/Loader";

const Info = ({ icon, text }) => (
  <Stack direction="row" alignItems="center" spacing={2}>
    <Icon size="extraSmall" icon={icon} color="black" />
    <Typography variant="textSm">{text}</Typography>
  </Stack>
)

const displayDate = date => dayjs(date).format('ddd, MMM, DD')
const displayTime = date => dayjs(date).format('HH:mm')

const EventPopup = ({events, close, isLoading }) => {
  const [index, setIndex] = useState(0)
  const event = events[index] || {}
  const { name, description, start_date, estimate_occupancy, id} = event;
  const { colors } = useTheme()
  const { open: openEditModal } = useModal(AddEvent)
  const deleteEvent = useDeleteEventModal(id)

  const handleEdit = () => {
    openEditModal({
      title: 'Edit Event',
      data: snakeToCamel(event)
    })
      .then(() => close())
  }

  useEffect(() => {
    setIndex(0)
  }, [events]);

  const goNext = () => setIndex(Math.min(events.length - 1, index + 1))
  const goPrev = () => setIndex(Math.max(0, index - 1))

  return (
    <Paper sx={{ width: '450px', backgroundColor: 'white' }}>
      <Loader loading={isLoading} height="250px">
        <>
          <Modal.Title
            popup
            description={description}
            maxDescriptionLength={135}
          >{name}
          </Modal.Title>
          <Modal.Content>
            <Stack spacing={4}>
              <Stack direction="row" justifyContent="space-between" spacing={2}>
                <Info icon="calendar" text={displayDate(start_date)} />
                <Info icon="clock" text={displayTime(start_date)} />
                <Info icon="group" text={estimate_occupancy} />
              </Stack>
              {events.length > 1 && (
                <Stack direction="row" justifyContent="flex-end" alignItems="center">
                  <IconButton onClick={goPrev} size="small">
                    <Icon icon="chevronLeft" />
                  </IconButton>
                  <Typography variant="textSm">{`${index + 1} / ${events.length}`}</Typography>
                  <IconButton onClick={goNext} size="small">
                    <Icon icon="chevronRight" />
                  </IconButton>
                </Stack>
              )}
            </Stack>
          </Modal.Content>
          <Modal.Footer>
            <Stack spacing={3} direction="row">
              <Button sx={{ flex: 1 }} color="secondary" onClick={deleteEvent}><Icon icon="trashBin" color={colors.red.primary} /></Button>
              <Button sx={{ flex: 1 }} color="secondary" onClick={handleEdit}><Icon icon="pencil" /></Button>
            </Stack>
          </Modal.Footer>
          {close && (
            <IconButton
              aria-label="close"
              onClick={close}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </>
      </Loader>
    </Paper>
  );
};

export default EventPopup;