import React from 'react';
import MainCalendar from 'rsuite/Calendar';
import {styled} from "@mui/material/styles";

const StyleCalendar = styled(MainCalendar)(({ theme }) => ({
  backgroundColor: theme.colors.gray['100'],
  width: '320px',
  borderRadius: '12px',
  '& .rs-calendar-btn-today': {
    display: 'none',
  },
  '& .rs-calendar-header-month-toolbar': {
    display: 'flex !important',
    width: '100% !important',
    justifyContent: 'space-between',
    '&::after': {
      display: 'none !important',
    },
    '&::before': {
      display: 'none !important',
    }
  },
  '& .rs-calendar-table-cell-content': {
    width: '40px !important;',
    height: '40px !important;',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    '& .rs-calendar-table-cell-day': {
      margin: '0 0 0'
    },
    '&:hover': {
      backgroundColor: `${theme.colors.yellow.light}`,
    }
  },
  '& .rs-calendar-table-cell': {
    '&.rs-calendar-table-cell-selected': {
      '& .rs-calendar-table-cell-content': {
        backgroundColor: theme.colors.yellow.primary,
        color: 'white',
        boxShadow: 'none',
        '& .rs-calendar-table-cell-day': {
          backgroundColor: theme.colors.yellow.primary,
        }
      }
    },
    '&.rs-calendar-table-cell-is-today .rs-calendar-table-cell-day': {
      backgroundColor: theme.colors.yellow.primary,
    }
  }
}));

const Calendar = (props) => {
  return (
    <StyleCalendar {...props} compact />
  );
};

export default Calendar;