import React, {useEffect} from 'react';
import Popper from "@mui/material/Popper";
import useClickOutside from "../../hooks/useClickOutside";

const NOOP = () => {}

const Popup = ({ children, renderPopup, placement, enabled = true, outsideClose = true, isOpen }) => {
  const [anchor, setAnchor] = React.useState(null);
  const close = () => setAnchor(null);
  const toggle = event => {
    event.stopPropagation()
    if (enabled) {
      if (typeof isOpen === "boolean") {
        setAnchor(isOpen ? event.currentTarget : null)
        return;
      }
      setAnchor(anchor ? null : event.currentTarget);
    }
  }
  const ref = React.useRef(null);
  const containerRef = React.useRef(null)

  useClickOutside([ref, containerRef], outsideClose ? close : NOOP)

  useEffect(() => {
    if (typeof isOpen === "boolean") {
      setAnchor(isOpen ? containerRef.current : null)
    }
  }, [isOpen])

  return (
    <>
      <div ref={containerRef} onClick={toggle} style={{display: 'inline-block', cursor: 'pointer', maxWidth: '100%'}}>
        {children}
      </div>
      <Popper ref={ref} open={!!anchor} anchorEl={anchor} placement={placement}>
        {() => renderPopup({close})}
      </Popper>
    </>
  );
};

export default Popup;