import React, {useEffect, useMemo, useState} from 'react';
import Page from "../../../layouts/Page";
import Header from "../../../modules/venus/events/Header";
import UpcomingEvents from "../../../modules/venus/events/UpcomingEvents/UpcommingEvents";
import {Stack} from "@mui/material";
import {withSites} from "../../../hoc/withSites";
import useIsMobile from "../../../hooks/useIsMobile";
import {useModal} from "../../../hooks/useModal";
import AddEvent from "../../../modules/venus/events/modals/AddEvent";
import AllEvents from "../../../modules/venus/events/AllEvents";
import {useDispatch, useSelector} from "react-redux";
import {selectSelectedSite} from "../../../redux/sites";
import {useParams} from "react-router-dom";
import {updateFilters} from "../../../redux/explore";
import { updateFilters as updateSideFilters } from '../../../redux/filters';

const Events = () => {
  const isMobile = useIsMobile()
  const { open } = useModal(AddEvent)
  const {siteId} = useParams()
  const site = useSelector(selectSelectedSite(siteId))
  const dispatch = useDispatch()
  const [isExpanded, setIsExpanded] = useState(false)
  const openAddEventModal = () => open({
    title: "Add Event",
  })
  const actions = useMemo(() => ([
    {
      title: isMobile ? '+' : '+ Add Event',
      props: {
        onClick: openAddEventModal,
      }
    }
  ]), [isMobile, openAddEventModal])

  useEffect(() =>  {
    dispatch(updateFilters({ sites: [site]}))
    dispatch(updateSideFilters({ site: [site]}))
  }, [site?.id]);

  const title = `${site?.name} Events`

  const toggleExpanded = () => setIsExpanded(_ => !_)

  return (
    <Page>
      <Stack spacing={4}>
        {!isExpanded && (
          <>
            <Header title={title} actions={actions}/>
            <UpcomingEvents />
          </>
        )}
        <AllEvents isExpanded={isExpanded} toggleExpanded={toggleExpanded} />
      </Stack>
    </Page>
  );
};

export default withSites(Events, { redesign: true, cache: true });
