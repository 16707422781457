import React from 'react';
import {Chip as MuiChip, Stack} from "@mui/material";
import {styled} from "@mui/material/styles";
import {Close, Face} from "@mui/icons-material";
import { ReactComponent as DotsIcon } from './dots.svg'
import { ReactComponent as DotsWhiteIcon } from './dots-white.svg'

const StyledChip = styled(MuiChip)(({ rounded, theme, color, clickable }) => ({
  ...(rounded ? {
    borderRadius: '200px',
    ...(color ? {} : { color:theme.colors.gray.secondary }),
    ...theme.typography.textXs,
    padding: '2px 6px',
  } : {
    ...theme.typography.textSm,
  }),
  fontWeight: 500,
}))

const Badge = ({ label, rounded, color, size, icon, onDrag, onDelete, clickable }) => {
  const chipSize = size || 'small'
  const DragIcon = color === 'info' ? DotsWhiteIcon : DotsIcon
  return (
    <StyledChip
      icon={icon}
      label={label}
      rounded={rounded}
      color={color}
      size={chipSize}
      onDelete={onDrag || onDelete}
      deleteIcon={onDrag ? <DragIcon /> : <Close />}
      clickable={clickable}
    />
  );
};

export default Badge;
