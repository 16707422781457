import React from 'react';
import Modal from "../../ui/Modal";
import {Button, Stack} from "@mui/material";
import {isFunc} from "../../utils/funcs";

const TYPE = {
  info: "info",
  variant: "variant",
  action: "action",
}

const Confirm = ({ modalProps, bind, close, withCloseButton }) => {
  const { title, description, confirmText, rejectText, onConfirm, type = TYPE.action, descriptionTip } = modalProps;
  const [disabled, setDisabled] = React.useState(false);

  const handleConfirm = () => {
    setDisabled(true)
    if(isFunc(onConfirm)) {
      return onConfirm()
        .then(bind)
        .finally(() => setDisabled(false))
    }
    return bind()
  };

  return (
    <Modal onClose={withCloseButton && close}>
      <Modal.Title descriptionTip={descriptionTip} description={description}>{title}</Modal.Title>
      <Modal.Footer>
        <Modal.Actions>
          {}
        </Modal.Actions>
        <Stack direction="row" spacing={3}>
          {type !== TYPE.info && (
            <Modal.Action>
              <Button fullWidth disabled={disabled} color="secondary" onClick={close}>
                {rejectText}
              </Button>
            </Modal.Action>
          )}
          <Modal.Action>
            <Button
              fullWidth
              disabled={disabled}
              color={type === TYPE.action ? 'error' : 'secondary'}
              onClick={handleConfirm}
            >
              {confirmText}
            </Button>
          </Modal.Action>
        </Stack>
      </Modal.Footer>
    </Modal>
  );
};

export default Confirm;