import { COLORS } from "../constants/base";
import {alpha} from "@mui/system";

const components = {
  MuiButton: {
    defaultProps: {
      disableRipple: true,
      variant: "contained",
      disableElevation: true,
    },
   styleOverrides: {
     root: ({ theme }) => ({
       borderRadius: '8px',
       padding: '10px 18px',
       '&.Mui-disabled': {
         opacity: .5,
         color: theme.palette.common.white,
         boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
       },
     }),
     containedPrimary: ({ theme }) => ({
       '&.Mui-disabled': {
         backgroundColor: alpha(theme.palette.primary.main, 0.3),
       }
     }),
     containedSecondary: ({ theme }) => ({
       border: `1px solid ${theme.colors.gray['200']}`,
       '&.Mui-disabled': {
         backgroundColor: alpha(theme.palette.secondary.main, 0.3),
         color: theme.palette.secondary.contrastText,
       },
       '&.Mui-startIcon svg': {
         color: theme.palette.secondary.contrastText,
       },
     }),
     containedError: ({ theme }) => ({
       '&.Mui-disabled': {
         backgroundColor: alpha(theme.palette.error.main, 0.3),
       }
     }),
     containedWarning: ({ theme }) => ({
       '&.Mui-disabled': {
         backgroundColor: alpha(theme.palette.warning.main, 1),
         color: theme.palette.warning.contrastText,
       },
       '&:hover': {
         color: theme.palette.common.white,
       }
     }),
     textPrimary: ({ theme }) => ({
       color: theme.palette.common.black,
       maxWidth: 'fit-content',
       boxShadow: 'none !important',
       outline: 'none',
       border: 'none',
       padding: 0,
       '&.Mui-startIcon svg': {
         color: theme.palette.secondary.contrastText,
       },
       '&:hover': {
         color: theme.colors.yellow.dark,
         background: 'transparent',
       },
       '&:hover .MuiButton-startIcon svg': {
         stroke: theme.colors.yellow.dark,
       },
       '&.Mui-disabled': {
         opacity: 1,
         color: theme.colors.gray.secondary,
       }
     }),
     sizeSmall: {
       padding: '10px 14px',
     },
   }
  },
  MuiFormLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.colors.other.label,
        ...theme.typography.textSm,
        fontWeight: 600,
        "&.Mui-focused": {
          color: theme.colors.other.label,
        },
        "&.Mui-error": {
          color: theme.colors.other.label,
        },
      }),
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        "&.Mui-error": {
          color: `${COLORS.red} !important`,
        },
        marginLeft: 0,
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: '8px',
        '&:hover:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.colors.yellow.primary,
        },
        '&:hover:not(.Mui-disabled).Mui-error .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.colors.red.primary,
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.colors.yellow.primary,
          boxShadow: '0px 0px 0px 4px rgba(255, 246, 220, 1), 0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
        },
        '&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.colors.red.primary,
          boxShadow: '0px 1px 2px 0px rgba(234, 67, 53, 0.05)',
        },
        '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${theme.colors.gray['100']}`,
        },
        "&.Mui-error .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.colors.red.dark,
        },
        padding: 0,
      }),
      input: ({ theme }) => ({
        '&::placeholder': {
          color: theme.colors.gray.secondary,
          fontSize: '16px',
        },
        fontSize: '16px',
        padding: "10px 14px",
        // TODO exclude text field
        '&.MuiInputBase-inputAdornedEnd': {
          textAlign: 'center',
        },
      }),
      notchedOutline: ({ theme }) => ({
        top: 0,
        borderRadius: '8px',
        borderColor: theme.colors.gray['200'],
        '& legend': {
          display: 'none'
        },
      }),
    }
  },
  // TODO exclude text field
  MuiInputAdornment: {
    styleOverrides: {
      root: {
        display: 'none',
      }
    }
  },
  MuiInputLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        fontSize: "14px",
        fontWeight: 600,
        display: 'inline',
        marginBottom: '12px',
        width: 'fit-content',
        position: 'relative',
        transform: 'none',
        color: theme.colors.other.label,
        maxWidth: '100% !important',
      }),
    },
  },
  MuiSelect: {
    styleOverrides: {
      root: {
        fontSize: "16px",
        "&.MuiInput-underline::before": {
          borderBottom: "1px solid #d3d8dd",
        },
        "&.MuiInput-underline::after": {
          borderBottom: `1px solid ${COLORS.gray}`,
        },
        "&.MuiInput-underline:hover:not(.Mui-disabled):before": {
          borderBottom: "1px solid #d3d8dd",
        },
        "&.Mui-focused .MuiSvgIcon-root": {
          color: COLORS.accent_blue,
        },
      },
      outlined: {
        '& ~ fieldset legend': {
          display: 'block',
          height: 0,
          '& span': {
            opacity: 1,
            position: 'absolute',
            overflow: 'visible',
            transform: 'translate(0, -50%)',
            top: '50%',
            fontSize: '14px',
            color: COLORS.gray,
          }
        },
        '& .MuiSvgIcon-root': {
          display: 'none',
        },
        '& .MuiListItemText-root': {
          marginBottom: 0,
          marginTop: 0,
        },
      },
    },
  },
  MuiList: {
    styleOverrides: {
      root: ({ theme }) => ({
        '& .MuiMenuItem-root.Mui-selected': {
          color: theme.colors.yellow.dark,
          background: theme.colors.yellow.light,
        }
      })
    }
  },
  MuiMenuItem: {
    styleOverrides: {
      root: ({ theme}) => ({
        ...theme.typography.textMd,
        fontWeight: '500',
        '&.MuiMenuItem-gutters': ({ theme }) => ({
          color: `${theme.colors.yellow.dark} !important`,
          fontSize: '32px',
        })
      })
    }
  },
  MuiTextField: {
    defaultProps: {
      InputLabelProps: { shrink: true },
    },
  },
  MuiLink: {
    defaultProps: {
      underline: "hover",
    },
  },
  MuiCardHeader: {
    defaultProps: {
      titleTypographyProps: {
        variant: "h6",
      },
    },
    styleOverrides: {
      action: {
        marginTop: "-4px",
        marginRight: "-4px",
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: "6px",
        boxShadow:
          "rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px",
        backgroundImage: "none",
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: ({ theme }) =>  ({
        backgroundImage: "none",
        borderRadius: "10px",
        '&.MuiDialog-paper': {
          backgroundColor: theme.palette.common.white,
        }
      }),
    },
  },
  MuiPickersDay: {
    styleOverrides: {
      day: {
        fontWeight: "300",
      },
    },
  },
  MuiPickersYear: {
    styleOverrides: {
      root: {
        height: "64px",
      },
    },
  },
  MuiPickersCalendar: {
    styleOverrides: {
      transitionContainer: {
        marginTop: "6px",
      },
    },
  },
  MuiPickersCalendarHeader: {
    styleOverrides: {
      iconButton: {
        backgroundColor: "transparent",
        "& > *": {
          backgroundColor: "transparent",
        },
      },
      switchHeader: {
        marginTop: "2px",
        marginBottom: "4px",
      },
    },
  },
  MuiPickersClock: {
    styleOverrides: {
      container: {
        margin: `32px 0 4px`,
      },
    },
  },
  MuiPickersClockNumber: {
    styleOverrides: {
      clockNumber: {
        left: `calc(50% - 16px)`,
        width: "32px",
        height: "32px",
      },
    },
  },
  MuiPickerDTHeader: {
    styleOverrides: {
      dateHeader: {
        "& h4": {
          fontSize: "2.125rem",
          fontWeight: 400,
        },
      },
      timeHeader: {
        "& h3": {
          fontSize: "3rem",
          fontWeight: 400,
        },
      },
    },
  },
  MuiPickersTimePicker: {
    styleOverrides: {
      hourMinuteLabel: {
        "& h2": {
          fontSize: "3.75rem",
          fontWeight: 300,
        },
      },
    },
  },
  MuiPickersToolbar: {
    styleOverrides: {
      toolbar: {
        "& h4": {
          fontSize: "2.125rem",
          fontWeight: 400,
        },
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: "6px",
        '& .MuiChip-deleteIcon': {
          margin: '0 0 0 0 !important',
        }
      },
      colorPrimary: {
        backgroundColor: "#2ED47A",
      },
      colorError: ({theme}) => ({
        backgroundColor: theme.colors.red.light,
        color: theme.colors.red.primary,
        '& .MuiChip-icon': {
          color: theme.colors.red.primary
        },
      }),
      colorWarning: {
        backgroundColor: "#FFB946",
      },
      colorInfo: ({theme}) => ({
        backgroundColor: theme.colors.other.chipInfo,
        '&:hover': {
          backgroundColor: theme.colors.other.chipInfo,
        },
        color: theme.palette.common.white,
        '& .MuiChip-icon': {
          color: `${theme.palette.common.white} !important`,
        },
      }),
      colorSuccess:  ({theme}) => ({
        backgroundColor: theme.colors.green.light,
        color: theme.colors.green.dark,
        '& .MuiChip-icon': {
          color: theme.colors.green.dark,
        },
      }),
      sizeSmall: {
        padding: '2px 6px'
      },
      sizeMiddle: {
        padding: '6px 12px',
      },
    },
  },
  MuiDataGrid: {
    styleOverrides: {
      root: ({ theme }) => ({
        '& .MuiDataGrid-columnHeaders': {
          backgroundColor: theme.colors.gray.strokes,
        },
        borderRadius: '12px',
        border: `1px solid ${theme.colors.gray.strokes}`
      }),
      columnSeparator: {
        display: "none",
      },
      columnHeaderTitle: ({ theme }) => ({
        ...theme.typography.textXs,
        fontWeight: 500,
      }),
      columnHeader: {
        '&:focus': {
          outline: "none",
        },
        padding: '12px 24px',
      },
      cellContent: ({ theme }) => ({
        ...theme.typography.textSm,
      }),
      cell: {
        '&:focus': {
          outline: 'none',
        },
        padding: '12px 24px',
        backgroundColor: 'white',
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
     root: ({ theme }) => ({
       '& .MuiSwitch-track': {
         backgroundColor: theme.colors.gray['100'],
         borderRadius: '20px',
       },
       '& .MuiSwitch-thumb': {
         width: '16px',
         height: '16px',
         boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.06),0px 1px 3px 0px rgba(16, 24, 40, 0.1) ',
       },
       '& .MuiSwitch-switchBase': {
         transform: 'translateY(6%)',
       },
       '& .MuiSwitch-switchBase.Mui-checked': {
         transform: 'translate(20px, 6%)',
         color: 'white',
         '&+.MuiSwitch-track': {
           opacity: 1,
         },
       },
       padding: '9px',
     })
    }
  },
  MuiTabs: {
    styleOverrides: {
      root: ({ theme }) => ({
        background: theme.colors.gray['50'],
        borderRadius: '8px',
        borderBottom: 'none',
        padding: '4px',
        '& .MuiTab-root': {
          flex: 1,
          padding: '6px 8px',
          background: 'transparent',
          borderBottom: 'none',
          borderRadius: '6px',
          minHeight: 'unset',
          '&.Mui-selected': {
            backgroundColor: theme.colors.yellow.primary,
            color: theme.palette.common.white,
          },
        },
        '& .MuiTabs-indicator': {
          display: 'none',
        }
      })
    }
  },
  MuiTabPanel: {
    styleOverrides: {
      root: {
        padding: '20px 0',
      }
    }
  },
  MuiSlider: {
    styleOverrides: {
      root: ({ theme }) => ({
        '& .MuiSlider-rail': {
          backgroundColor: theme.colors.gray['200'],
          height: '10px',
          borderRadius: '20px',
        },
        '& .MuiSlider-track': {
          backgroundColor: theme.colors.other.slider,
          border: `1px solid ${theme.colors.other.slider}`,
        },
        '& .MuiSlider-thumb': {
          backgroundColor: theme.palette.common.white,
          border: `2px solid ${theme.colors.other.slider}`,
          width: "14px",
          height: '14px',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
          '&::before, &::after': {
            display: 'none',
          },
          '&.Mui-focusVisible': {
            boxShadow: 'none',
          }
        },
        '& .MuiSlider-markLabel': {
          fontSize: '14px',
        }
      })
    }
  },
  MuiDialogContent: {
    styleOverrides: {
      root: () => ({
        padding: '24px',
        paddingTop: 0,
      })
    }
  },
  MuiDialog: {
    styleOverrides: {
      root: () => ({
        background: 'rgba(255, 255, 255, 0.1)',
        '& .MuiBackdrop-root': {
          backdropFilter: "blur(2px)"
        },
        '& .MuiDialog-paperWidthXs': {
          maxWidth: '568px',
        }
      })
    }
  },
  MiuTypography: {
    styleOverrides: {
      colorSecondary: ({ theme }) => ({
        color: theme.palette.text.secondary
      }),
    }
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backgroundColor: 'black',
        padding: '8px',
        borderRadius: "12px",
      },
      arrow: {
        '&:before': {
          backgroundColor: 'black',
        },
      }
    }
  }
};

export default components;
