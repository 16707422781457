import Modal from "../../ui/Modal";
import {Stack, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import React from "react";

const SimpleModal = ({ bind, close, modalProps, ...props }) => {
  return (
    <Modal {...props} onClose={close}>
      <Modal.Title description="Some text">My Dialog</Modal.Title>
      <Modal.Content>
        <TextField placeholder="Enter your name" fullWidth label="Name" />
      </Modal.Content>
      <Modal.Footer>
        <Stack direction="row" gap={4}>
          <Button sx={{ flex: 1 }} color="secondary" onClick={() => close()}>Cansel</Button>
          <Button sx={{ flex: 1 }} onClick={() => bind('Good')}>Ok</Button>
        </Stack>
      </Modal.Footer>
    </Modal>
  )
}

export default SimpleModal;
