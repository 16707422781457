import React from 'react';
import {Box} from "@mui/material";
import { icons } from "./icons";
import {styled} from "@mui/material/styles";
import PropTypes from "prop-types";

export const sizeMap = {
  extraTiny: '14px',
  tiny: '16px',
  extraSmall: '18px',
  small: '20px',
  normal: '24px',
  medium: '26px',
  large: '40px',
};

const Wrapper = styled(Box)(({theme, size, color, rotate, stroke, hoverColor, fill }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: sizeMap[size],
  width: sizeMap[size],
  transform: `rotate(${rotate || 0})`,
  '& > svg': {
    width: "100%",
    height: '100%',
    fill,
    stroke: color || theme.colors.gray['900'],
    '&:hover': {
      fill: fill && hoverColor,
      stroke: stroke && hoverColor,
      '& path': {
        fill: fill && hoverColor,
        stroke: stroke && hoverColor,
      },
    },
    '& path': {
      fill,
      stroke: color || color || theme.colors.gray['900'],
    },
  }
}))

const Icon = (props) => {
  const Icon = icons[props.icon]
  if (!Icon) {
    return null
  }

  return (
    <Wrapper {...props}>
      <Icon />
    </Wrapper>
  );
};

Icon.defaultProps = {
  size: 'normal',
}

Icon.propTypes = {
  icon: PropTypes.oneOf(Object.keys(icons)),
  size: PropTypes.oneOf(Object.keys(sizeMap)),
}

export default Icon;
