import React, {useEffect, useState} from 'react';
import {Area, Pagination} from "../../../../ui";
import {Stack} from "@mui/material";
import Header from "./components/Header";
import EventsTable from "./components/EventsTable";
import {useParams} from "react-router-dom";
import useSWR from "swr";
import {getPathFromParams} from "../../../../routes";
import {events} from "../../../../utils/swrs/urls";
import {fetcher} from "../../../../utils/axios";
import Loader from "../../../../ui/Loader";
import {useQueryFilters} from "../../../../hooks/useQueryFilters";
import {INITIAL_EVENTS_FILTERS} from "../constants";
import {camelToSnake} from "../../../../utils/formaters/snakeCase";
import {formatDayRangeToRequest} from "../../../../utils/data/formatPayload";
import {pipe} from "../../../../utils/pipe";
import {usePagination} from "../../../../hooks/usePagination";


const AllEvents = ({ isExpanded, toggleExpanded }) => {
  const { siteId } = useParams()
  const { filters, setFilters } = useQueryFilters(INITIAL_EVENTS_FILTERS)
  const [total, setTotal] = useState(0)
  const { limit, offset, ...pagination} = usePagination({ total })

  const { data, isLoading } = useSWR(
    [
      getPathFromParams(events.baseUrl, { site: siteId }),
      pipe(
        {...filters, limit, offset },
        formatDayRangeToRequest,
        camelToSnake
      )
    ],
    fetcher
  )


  useEffect(() => {
    if(data?.count) {
      setTotal(data.count)
    }
  }, [data?.count]);

  const applyFilters = newFilters => setFilters({ ...newFilters, page: '' })

  return (
    <Area>
      <Stack spacing={4} sx={{ height: '100%' }}>
        <Header
          expanded={isExpanded}
          toggleExpand={toggleExpanded}
          onFiltersChange={applyFilters}
          filters={filters}
        />
        <Loader loading={isLoading}>
          <EventsTable data={data?.results} />
          {!!data?.results?.length && (
            <Pagination {...pagination} />
          )}
        </Loader>
      </Stack>
    </Area>
  );
};

export default AllEvents;