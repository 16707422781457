import React, {useState} from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  TextField,
  Slider,
  Switch,
  Stack,
  Button, Box, InputAdornment,
} from "@mui/material";
import { spacing } from "@mui/system";
import {ThemeProvider} from "@mui/material/styles";
import createTheme from "../../theme";
import {THEMES} from "../../constants/base";
import {Area, Badge, DatePicker, Icon, Select, Stepper, Table, InputField} from "../../ui";
import TimePicker from "../../ui/TimePicker";
import Tabs from "../../ui/Tabs";
import sampleModal from "../../modals/Smaple";
import {useModal} from "../../hooks/useModal";
import {FaceRounded, SettingsAccessibility} from "@mui/icons-material";
import {icons} from "../../ui/Icon/icons";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const First = () => (
  <Area>
    <h2>First</h2>
  </Area>
)
const Second = () => (
  <Area>
    <h2>Second</h2>
  </Area>
)
const Third = () => (
  <Area>
    <h2>Third</h2>
  </Area>
)

const TABS = [
  {
    title: 'First',
    Component: First,
  },
  {
    title: 'Second',
    Icon: FaceRounded,
    Component: Second,
  },
  {
    title: 'third',
    Icon: SettingsAccessibility,
    Component: Third,
  },
]

const marks = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 10,
    label: '10%',
  },
  {
    value: 20,
    label: '20%',
  },
  {
    value: 30,
    label: '30',
  },
  {
    value: 50,
    label: '50%',
  },
  {
    value: 100,
    label: '100%',
  },
];

const rows = [
  { id: 1, lastName: 'Snow', firstName: 'Jon', age: 14 },
  { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 31 },
  { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 31 },
  { id: 4, lastName: 'Stark', firstName: 'Arya', age: 11 },
  { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
  { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
];

const columns = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'firstName',
    headerName: 'First name',
    flex: 1,
  },
  {
    field: 'lastName',
    headerName: 'Last name',
    flex:1,
  },
  {
    field: 'age',
    headerName: 'Age',
    type: 'number',
    flex: 1,
  },
];

const space = 4

function Blank() {
  const options = [
    {
      label: 'First',
      value: 1,
    },
    {
      label: 'Second',
      value: 2,
    },
    {
      label: 'Third',
      value: 3,
    },
  ]
  const [open, setOpen] = useState(false)
  const { open: mOpen } = useModal(sampleModal)
  const { open: openSide } = useModal(sampleModal, { side: true })
  const [select, setSelect] = useState(1)
  const openModal = () => mOpen()
    .then(res => console.log(res))
  return (
    <>
      <Helmet title="Blank" />
      <Typography variant="h3" gutterBottom display="inline">
        Blank
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/">
          Pages
        </Link>
        <Typography>Blank</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6} sx={{ background: 'white', padding: "30px" }}>
        <Grid item xs={12}>
          <ThemeProvider theme={createTheme(THEMES.REDESIGN)}>
            <Stack spacing={space}>
              <Area>
                <Stack spacing={space * 2}>
                  <Typography variant="displayXs">Buttons</Typography>
                  <Stack direction="row" spacing={space}>
                    <Button>Primary</Button>
                    <Button color="secondary">Secondary</Button>
                    <Button color="warning">Tertiary</Button>
                    <Button color="error">Error</Button>
                    <Button variant="text">Text</Button>
                  </Stack>
                </Stack>
              </Area>
              <Area>
                <Stack spacing={space * 2}>
                  <Typography variant="displayXs">Inputs</Typography>
                  <Stack direction="row" spacing={space}>
                    <TextField label="Regular" placeholder="Text here.." />
                    <TextField label="Disabled" disabled />
                    <TextField
                      helperText="Error text"
                      label="Error"
                      error
                    />
                    <InputField error label="Error input" />
                  </Stack>
                  <Switch label="Switch" checked={open} onChange={(event) => setOpen(event.target.checked)} />
                  <Select value={select} onChange={e => setSelect(e.target.value)} label="Dropdown" name="rot" options={options} />
                  <TextField
                    multiline
                    rows={6}
                    placeholder="Text here..."
                    label="Text area"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          P
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>
              </Area>
              <Area>
                <Stack spacing={space * 2}>
                  <Typography variant="displayXs">Date/Time picker</Typography>
                  <Stack direction="row" spacing={space}>
                    <DatePicker label="Date picker" />
                    <TimePicker label="Time picker" onChange={date => console.log(date)} />
                  </Stack>
                </Stack>
              </Area>
              <Area>
                <Stack spacing={space * 2}>
                  <Typography variant="displayXs">Badges</Typography>
                  <Stack direction="row" spacing={space}>
                    <Badge onDrag={() => null} label="Hello" color="info" />
                    <Badge onDelete={() => null} label="Hello" color="info" />
                    <Badge label="Success" color="success" />
                    <Badge label="Success" color="success" rounded />
                    <Badge label="Error" color="error"/>
                    <Badge label="Regular" />
                    <Badge label="With icon" icon={<FaceRounded />} color="info" />
                  </Stack>
                </Stack>
              </Area>
              <Typography variant="displayXs">Tabs</Typography>
              <Tabs tabs={TABS} />
              <Typography variant="displayXs">Slider</Typography>
              <Slider marks={marks} step={10} />
              <Box sx={{paddingTop: '30px'}}>
                <Area>
                  <Stack spacing={space * 2}>
                    <Typography variant="displayXs">Modals</Typography>
                    <Stack direction="row" spacing={space}>
                      <Button onClick={openModal}>Center</Button>
                      <Button onClick={openSide}>Side</Button>
                    </Stack>
                  </Stack>
                </Area>
              </Box>
              <Area>
                <Stack spacing={space * 2}>
                  <Typography variant="displayXs">Icons</Typography>
                  <Typography>Regular</Typography>
                  <Stack flexWrap="wrap" direction="row" spacing={2}>
                    {Object.keys(icons).map(icon => (
                      <Icon icon={icon} key={icon} />
                    ))}
                  </Stack>
                  <Typography>Medium</Typography>
                  <Stack flexWrap="wrap" direction="row" spacing={2}>
                    {Object.keys(icons).map(icon => (
                      <Icon icon={icon} size="medium" key={icon} />
                    ))}
                  </Stack>
                  <Typography>Heavyweight</Typography>
                  <Stack flexWrap="wrap" direction="row" spacing={2}>
                    {Object.keys(icons).map(icon => (
                      <Icon icon={icon} size="large" key={icon} />
                    ))}
                  </Stack>
                </Stack>
              </Area>
              <Area>
                <Stack spacing={space * 2}>
                  <Typography variant="displayXs">Table</Typography>
                  <Table columns={columns} rows={rows} />
                </Stack>
              </Area>
            </Stack>
          </ThemeProvider>
        </Grid>
      </Grid>
    </>
  );
}

export default Blank;
