import React from 'react';
import {Box} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";

const Table = (props) => {
  return (
    <Box sx={{ width: '100%' }}>
      <DataGrid
        {...props}
        disableColumnMenu
        hideFooter
      />
    </Box>
  );
};

export default Table;
