// Themes
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
  REDESIGN: "REDESIGN",
};

// Colors
export const COLORS = {
  lightBlue: "#9ADEFA",
  accent_blue: "rgba(57, 111, 169, 1)", // remove
  accentBlue: "rgba(57, 111, 169, 1)",
  table_black: "#323C47",
  table_gray: "#707683",
  dark_blue: "#334D6E",
  gray_icon: "#C2CFE0",
  activeBlue: "rgba(25, 31, 66, 1)",

  blue: "#04A9F3",
  blueHover: "#34AFF9",
  bluePressed: "#098EDF",

  red: "#F7685B",
  redHover: "#fc8c82",
  redPressed: "#f94e3e",
  negative: "#D96959",

  brandYellow: "#FFCE20",
  yellow: "#FFB946",
  yellowHower: "#ffca75",
  yellowPressed: "#ffaf2e",

  green: "#2ED47A",
  greenHower: "#51e192",
  greenPressed: "#24c66d",
  positive: '#37DBA0',

  purple: "#885AF8",
  purpleHower: "#a481fd",
  purplePressed: "#733dfa",

  black: "#192A3E",
  tableBlack: "#323C47",
  tableGray: "#707683",
  darkBlue2: '#2B3674',

  darkBlue: "#334D6E",
  gray: "#90A0B7",
  dark_gray: "#757F8C",
  gray_light: "#D6D9E4",
  grayIcon: "#C2CFE0",
  inputBorder: '#A3AED0',
  inputBorderHover: '#668CFF',
  gray2: '#E9EDF7',

  white: "#FFFFFF",
  whiteSElected: "#F5F6F8",
  disabled: "#C2CFE0",
};

export const PAGINATION_SMALL = 5;
export const PAGINATION_MEDIUL = 10;
export const PAGINATION_LARGE = 20;
export const paginationLimit = 20;
export const paginationLimit10 = 10;
export const overviewPaginationLimit = 20;

export const kitchenLocation = [
  { key: "FRONT_OF_HOUSE", value: "Front of House" },
  { key: "POT_WASH", value: "Pot Wash" },
  { key: "WASH_UP_AREA", value: "Wash up Area" },
  { key: "PANTRY", value: "Pantry" },
  { key: "PREP_AREA", value: "Prep area" },
  { key: "DISHWASH", value: "Dishwash" },
];

export const ALL_OPTION_MULTI = {
  label: 'All',
  value: [],
}

export const ALL_OPTION = {
  label: 'All',
  value: '',
}

export const ITEMS_PER_PAGE = 10;

export const ONLY_NUMBERS_REGEX = /^\d+$/
