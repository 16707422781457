import React from "react";
import { SnackbarContent, closeSnackbar } from "notistack";
import {Box, IconButton, Stack, Typography} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {styled, ThemeProvider} from "@mui/material/styles";
import {THEMES} from "../../constants/base";
import createTheme from "../../theme";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { ReactComponent as SuccessIcon } from './success.svg'

const MAP_COLORS = {
  success: 'green',
  error: 'red',
}

const Wrapper = styled(Stack)(({ theme, color }) => ({
  background: theme.colors[MAP_COLORS[color]].light,
  padding: '12px',
  borderRadius: '8px',
  minWidth: '350px',
}));

const StyledCloseOutlinedIcon = styled(CloseOutlinedIcon)(({ theme, color }) => ({
  color: theme.colors[MAP_COLORS[color]].dark,
  width: '15px',
  height: '15px',
}));

const StyledText = styled(Typography)(({ theme, color }) => ({
  color: theme.colors[MAP_COLORS[color]].dark,
  fontWeight: 500,
}));

const StyledCancelOutlinedIcon = styled(CancelOutlinedIcon)(({ theme, color }) => ({
  color: theme.colors[MAP_COLORS[color]].dark,
}));

const MAP_ICON = {
  success: SuccessIcon,
  error: StyledCancelOutlinedIcon
}

const Alert = React.forwardRef((props, ref) => {
  const { id, message, variant } = props;
  const Icon = MAP_ICON[variant]
  return (
    <SnackbarContent ref={ref} role="alert">
      <Wrapper direction="row" justifyContent="space-between" alignItems="center" color={variant}>
        <Stack direction="row" alignItems="center" spacing={4}>
          <Icon color={variant} />
          <Box>
            <StyledText variant="textSm" color={variant}>{message}</StyledText>
          </Box>
        </Stack>
        <IconButton
          onClick={() => closeSnackbar(id)}
        >
          <StyledCloseOutlinedIcon width={10} color={variant} />
        </IconButton>
      </Wrapper>
    </SnackbarContent>
  );
});

export default Alert;
