import React, {memo, useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import {
  DialogContent,
  Divider, FormControl,
  FormGroup, InputLabel, ListSubheader, MenuItem, Select,
  Stack, Typography,
} from "@mui/material";
import { useLocation, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import ReviewSelectStandard from "../inputs/ReviewSelectStandard";
import SwitchFilter from "../filter/components/SwitchFilter";
import {
  selectFilters,
  updateDisplay,
  updateFilters,
} from "../../redux/filters";
import { fetchSites, selectSites } from "../../redux/sites";
import { fetchFoods, selectFoods } from "../../redux/foods";
import { fetchZones, selectZones } from "../../redux/zones";
import constants from "../filter/configs/constants";
import CustomButton from "../components/Buttons/CustomButton";
import useToSearchParams from "../../hooks/useToSearchParams";
import {
  fetchParentGroups,
  selectParentGroups,
} from "../../redux/parentGroups";
import formatInitialListState from "../../utils/formaters/formatInitialListState";
import ExportDataCSV from "../components/Buttons/ExportDataCSV";
import AddRecipeButton from "../components/Buttons/AddRecipeButton";
import DateInput from "../inputs/DateInput";
import useSWR from "swr";
import {events as swrEvents} from "../../utils/swrs/urls";
import {fetcher} from "../../utils/axios";
import { updateFilters as updateExploreFilters } from '../../redux/explore'

function FilterForm({ onCancel, fullWidth }) {
  const { pathname } = useLocation();

  const toSearchParams = useToSearchParams();

  const isReviewPage = useMemo(() => pathname.includes("review"), [pathname]);
  const isFeedPage = useMemo(() => pathname.includes("feed"), [pathname]);
  const isUserPage = useMemo(() => pathname.includes("user"), [pathname]);
  const isSitesPage = useMemo(() => pathname.includes("site"), [pathname]);
  const isManagmentPage = useMemo(() => pathname.includes("manag"), [pathname]);
  const isFoodsPage = useMemo(() => pathname.includes("foods"), [pathname]);
  const isMonitorsPage = useMemo(
    () => pathname.includes("monitors"),
    [pathname]
  );
  const isLabellingPage = useMemo(() => pathname.includes("labelling"), [pathname]);
  const isVerificationPage = useMemo(() => pathname.includes("verification"), [pathname]);

  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const { filters, view } = useSelector(selectFilters);
  const { sites, isLoading: isLoadingSites } = useSelector(selectSites);
  const { foods, isLoading: isLoadingFoods } = useSelector(selectFoods);
  const { zones, isLoading: isLoadingZones } = useSelector(selectZones);
  const { parentGroups, isLoading: isLoadingGroup } =
    useSelector(selectParentGroups);

  React.useEffect(() => {
    if (!isLoadingSites && sites.length === 0) {
      dispatch(fetchSites({ fields: ["id", "name"] }));
    }
    if (!isLoadingFoods && zones.length === 0) {
      dispatch(fetchZones());
    }
    if (!isLoadingZones && foods.length === 0) {
      const foodParams = {
        date: [filters.startDate, filters.endDate],
      };
      if (params.zone.length > 0) {
        foodParams.serial = params.zone.map((zone) => zone.serial);
      }
      dispatch(fetchFoods(foodParams));
    }
    if (!isLoadingGroup && parentGroups.length === 0) {
      dispatch(fetchParentGroups());
    }
  }, [dispatch]);

  const selectedSites = React.useMemo(() => {
    const siteList = formatInitialListState({
      ids: searchParams.getAll("siteId"),
      names: searchParams.getAll("siteName"),
    });
    return siteList.length ? siteList : filters.site;
  }, []);
  const selectedZones = React.useMemo(() => {
    const zoneList = formatInitialListState({
      ids: searchParams.getAll("zoneId"),
      names: searchParams.getAll("zoneName"),
      otherName: "serial",
      otherValue: searchParams.getAll("zoneSerial"),
    });
    return zoneList.length ? zoneList : filters.zone;
  }, []);
  const selectedFoods = React.useMemo(() => {
    const foodList = formatInitialListState({
      ids: searchParams.getAll("foodId"),
      names: searchParams.getAll("foodName"),
    });
    return foodList.length ? foodList : filters.food;
  }, []);
  const selectedGroups = React.useMemo(() => {
    const groupList = formatInitialListState({
      ids: searchParams.getAll("groupId"),
      names: searchParams.getAll("groupName"),
    });
    return groupList.length ? groupList : filters.group;
  }, []);
  const [ordering, setOrdering] = useState(filters.ordering);
  const [site, setSite] = React.useState(selectedSites);
  const [zone, setZone] = React.useState(selectedZones);
  const [food, setFood] = React.useState(selectedFoods);
  const [startDate, setStartDate] = useState(filters.startDate);
  const [endDate, setEndDate] = useState(filters.endDate);
  const [group, setGroup] = React.useState(selectedGroups);
  const [role, setRole] = useState(filters.role);
  const [status, setStatus] = useState(filters.status);
  const [autoLabel, setAutoLabel] = useState(filters.autoLabel);
  const [trimmings, setTrimmings] = useState(
    filters.trimmings === true ? true : null
  );
  const [withoutTrimmings, setWithoutTrimmings] = useState(
    filters.trimmings === false ? true : null
  );
  const [secondaryWaste, setSecondaryWaste] = useState(filters.secondaryWaste);
  const [nonFood, setNonFood] = useState(filters.nonFood);
  const [event, setEvent] = useState(filters.event)

  const withoutOrdering =
    isSitesPage || isMonitorsPage || isFoodsPage || isFeedPage;

  const params = useMemo(() => {
    const pageParams = {
      site,
      zone,
      food,
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      trimmings:
        trimmings === true ? true : withoutTrimmings === true ? false : null,
      secondaryWaste,
      nonFood,
      role,
      status,
      group,
      autoLabel,
      event,
    };

    if (!withoutOrdering) {
      pageParams.ordering = ordering;
    }

    return pageParams;
  }, [
    site,
    zone,
    food,
    startDate,
    endDate,
    ordering,
    trimmings,
    withoutTrimmings,
    secondaryWaste,
    nonFood,
    role,
    status,
    group,
    autoLabel,
    event,
  ]);

  const submitHandler = () => {
    if (params.startDate > params.endDate) {
      const temp = params.startDate;
      setStartDate(params.endDate);
      setEndDate(temp);
      params.startDate = startDate;
      params.endDate = endDate;
    }
    dispatch(updateExploreFilters({ sites: params.site }))
    dispatch(updateFilters({ ...filters, ...params }));
    const searchParams = toSearchParams(params);

    delete searchParams.display;
    delete searchParams.condense;

    setSearchParams(searchParams);

    const foodParams = {
      date: [filters.startDate, filters.endDate],
    };
    if (params.zone.length > 0) {
      foodParams.serial = params.zone.map((zone) => zone.serial);
    }
    dispatch(fetchFoods(foodParams));

    onCancel();
  };

  const trimmingsHandler = (value) => {
    setTrimmings(value === true ? true : null);
    setWithoutTrimmings(null);
    setSecondaryWaste(false);
    setNonFood(false);
  };

  const withoutTrimmingsHandler = (value) => {
    setWithoutTrimmings(value === true ? true : null);
    setTrimmings(null);
    setSecondaryWaste(false);
    setNonFood(false);
  };

  const secondaryWasteHandler = (value) => {
    dispatch(updateDisplay({ secondaryWaste: value }));
    setTrimmings(false);
    setWithoutTrimmings(null);
    setSecondaryWaste(value);
    setNonFood(false);
  };

  const nonFoodHandler = (value) => {
    setTrimmings(false);
    setWithoutTrimmings(null);
    setSecondaryWaste(false);
    setNonFood(value);
  };

  const handleDisplay = (value) => {
    dispatch(updateDisplay({ display: value }));
  };

  const handleCondense = (value) => {
    dispatch(updateDisplay({ condense: value }));
  };

  const handleWithSecondaryWaste = (value) => {
    dispatch(updateDisplay({ withSecondaryWaste: value }));
  };

  const handleOrdering = (value) => {
    if (withoutOrdering) {
      dispatch(updateDisplay({ ordering: value }));
    } else {
      setOrdering(value);
    }
  };

  const displayItems = useMemo(() => {
    // return constants.display.items
    if (isSitesPage || isMonitorsPage) {
      return constants.display.fullItems;
    } else {
      const items = constants.display.items;
      if (!items.find((i) => i.id === view.display.id)) {
        dispatch(updateDisplay({ display: constants.display.items[0] }));
      }
      return items;
    }
  }, [pathname]);

  function getOrderItems() {
    switch (true) {
      case isFoodsPage:
        return constants.ordering.foodItems;
      case isSitesPage:
      case isMonitorsPage:
        return constants.ordering.shortItems;
      case isFeedPage:
        return constants.ordering.feedItems;
      default:
        return constants.ordering.items;
    }
  }

  const orderingItems = useMemo(() => {
    const items = getOrderItems();

    if (!items.find((i) => i.id === view.ordering.id)) {
      dispatch(updateDisplay({ ordering: constants.ordering.shortItems[0] }));
    }

    return items;
  }, [pathname]);


  const siteIds = useMemo(() => site.map(site => site.id), [site])

  const { data: eventList } = useSWR(
    [swrEvents.pastUpcomingEvents, {
      sites: siteIds,
    }],
    fetcher,
    {
      fallbackData: { upcoming: [], past: [] },
      revalidateOnFocus: false,
      keepPreviousData: true,
    }
  )

  const eventAllList = useMemo(() => eventList.upcoming.concat(eventList.past), [eventList])

  useEffect(() => {
    if (event) {
      const eventData = eventAllList.find(({ id }) => id === event)
      if (eventData) {
        setStartDate(eventData.start_date);
        setEndDate(eventData.end_date);
      }
    }
  }, [event]);


  const handleSiteChange = value => {
    setSite(value);
    setEvent('')
  }

  return (
    <>
      <DialogContent>
        <Stack spacing={5}>
          <Typography variant="medium">Filters</Typography>
          <Divider />
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            spacing={5}
          >
            {isReviewPage && (
              <ReviewSelectStandard
                multiple
                isTitle
                fullWidth={fullWidth}
                value={status}
                setValue={setStatus}
                items={constants.status.items}
                label={constants.status.label}
                variant="outlined"
              />
            )}
            {(isFeedPage || isSitesPage || isMonitorsPage || isFoodsPage) && (
              <>
                <ReviewSelectStandard
                  fullWidth={fullWidth}
                  label={constants.ordering.label}
                  value={withoutOrdering ? view.ordering : ordering}
                  setValue={handleOrdering}
                  items={orderingItems}
                  variant="outlined"
                />
                <ReviewSelectStandard
                  fullWidth={fullWidth}
                  label={constants.display.label}
                  value={view.display}
                  setValue={handleDisplay}
                  items={displayItems}
                  variant="outlined"
                />
              </>
            )}
            {!isSitesPage && (
              <ReviewSelectStandard
                multiple
                fullWidth={fullWidth}
                label={constants.site.label}
                value={site}
                setValue={handleSiteChange}
                items={isLoadingSites ? [] : sites}
                variant="outlined"
              />
            )}
            {!(isSitesPage || isUserPage) && (
              <ReviewSelectStandard
                multiple
                fullWidth={fullWidth}
                label={constants.zone.label}
                value={zone}
                setValue={setZone}
                items={isLoadingZones ? [] : zones}
                variant="outlined"
              />
            )}
            {(isReviewPage || isFeedPage || isFoodsPage) && (
              <ReviewSelectStandard
                multiple
                fullWidth={fullWidth}
                label={constants.food.label}
                value={food}
                setValue={setFood}
                items={isLoadingFoods ? [] : foods}
                variant="outlined"
              />
            )}
            {isReviewPage && (
              <ReviewSelectStandard
                fullWidth={fullWidth}
                value={autoLabel}
                setValue={setAutoLabel}
                items={constants.autoLabel.items}
                label={constants.autoLabel.label}
                emptyLabel="All"
                emptyValue="All"
                variant="outlined"
              />
            )}
            {!(isUserPage || isManagmentPage || isLabellingPage || isVerificationPage) && (
              <>
                <DateInput
                  isStartDate
                  fullWidth
                  label="Start Date"
                  date={startDate}
                  setDate={setStartDate}
                  variant="outlined"
                />
                <DateInput
                  fullWidth
                  label="End Date"
                  date={endDate}
                  setDate={setEndDate}
                  variant="outlined"
                />
              </>
            )}
            {isFeedPage && (
              <FormControl
                variant="outlined"
                fullWidth
              >
                <InputLabel id="event">Event</InputLabel>
                <Select
                  id="event"
                  name="event"
                  value={event}
                  onChange={e => setEvent(e.target.value)}
                  displayEmpty
                >
                  <MenuItem value=''>All</MenuItem>
                  <ListSubheader>Upcoming</ListSubheader>
                  {eventList.upcoming.map(event => (
                    <MenuItem key={event.id} value={event.id}>{event.name}</MenuItem>
                  ))}
                  <ListSubheader>Past</ListSubheader>
                  {eventList.past.map(event => (
                    <MenuItem key={event.id} value={event.id}>{event.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {isFoodsPage && (
              <SwitchFilter
                label={constants.withSecondaryWaste.label}
                value={view.withSecondaryWaste}
                setValue={handleWithSecondaryWaste}
              />
            )}
            {(isSitesPage || isMonitorsPage || isFoodsPage) && (
              <SwitchFilter
                label={constants.condense.label}
                value={view.condense}
                setValue={handleCondense}
              />
            )}
            {isFeedPage && (
              <FormGroup>
                <SwitchFilter
                  label={constants.trimmings.withoutLabel}
                  value={withoutTrimmings}
                  setValue={withoutTrimmingsHandler}
                />
                <SwitchFilter
                  label={constants.secondaryWaste.label}
                  value={secondaryWaste}
                  setValue={secondaryWasteHandler}
                />
                <SwitchFilter
                  label={constants.nonFood.label}
                  value={nonFood}
                  setValue={nonFoodHandler}
                />
              </FormGroup>
            )}
            {(isUserPage || isManagmentPage) && (
              <ReviewSelectStandard
                multiple
                fullWidth={fullWidth}
                label={constants.group.label}
                value={group.length ? group : filters.group}
                setValue={setGroup}
                items={isLoadingGroup ? [] : parentGroups}
                variant="outlined"
              />
            )}
            {isUserPage && (
              <ReviewSelectStandard
                multiple
                isTitle
                fullWidth={fullWidth}
                value={role === null ? filters.role : role}
                setValue={setRole}
                items={constants.role.items}
                label={constants.role.label}
                variant="outlined"
              />
            )}
          </Stack>
          <CustomButton fullWidth onClick={submitHandler} color="secondary">
            Apply filters
          </CustomButton>
          {(isSitesPage || isFoodsPage || isFeedPage) && (
            <>
              <Divider />
              <ExportDataCSV />
            </>
          )}
          {isFoodsPage && <AddRecipeButton />}
        </Stack>
      </DialogContent>
    </>
  );
}

FilterForm.defaultProps = {
  fullWidth: true,
};

FilterForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
};

export default memo(FilterForm);
