import React from 'react';
import {Box, Stack, Typography} from "@mui/material";
import placeholderImg from '../assets/placeholder.png'

const Placeholder = ({ img, title, subTitle }) => {
  return (
    <Stack sx={{ width: '100%', height: '100%' }} justifyContent="center" alignItems="center">
      <Stack alignItems="center" spacing={2}>
        <img style={{ maxWidth: '200px'}} src={img || placeholderImg} />
        <Box>
          <Typography component='h5' variant="textLg" align="center" fontWeight={600}>{title}</Typography>
          {subTitle && (
            <Typography component='p' variant="textSm" color="text.secondary" align="center">{subTitle}</Typography>
          )}
        </Box>
      </Stack>
    </Stack>
  );
};

export default Placeholder;
