import React, {useMemo} from 'react';
import {Box} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import PropTypes from "prop-types";

const sizeMap = {
  small: 6,
  medium: 10,
}

const colors = {
  primary: 'primary',
  tertiary: 'tertiary',
}

const Point = ({ size, color }) => {
  const { palette } = useTheme()
  const colorMap = useMemo(() => ({
    [colors.primary]: palette.primary.main,
    [colors.tertiary]: palette.warning.dark,
  }))
  return (
    <Box
      sx={{
        width: sizeMap[size],
        height: sizeMap[size],
        backgroundColor: colorMap[color],
        borderRadius: '50%'
    }}
    />
  );
};

Point.propTypes = {
  size: PropTypes.oneOf(Object.keys(sizeMap)),
  color: PropTypes.oneOf(Object.keys(colors)),
}

Point.defaultProps = {
  size: 'medium',
  color: colors.primary,
}

export default Point;
