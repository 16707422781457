import React, {useEffect} from 'react';
import {FormControl, MenuItem, Select, Stack, Tooltip, useMediaQuery} from "@mui/material";
import Button from "@mui/material/Button";
import { METRICS, METRICS_LABEL } from "../../constants/explore";
import useAppSelector from "../../hooks/useAppSelector";
import {useSearchParams} from "react-router-dom";
import {selectSites} from "../../redux/sites";
import {ALL_SITES} from "../../constants/widgets";
import {useExploreFilters} from "../explore/useExploreFilters";
import { faWeightHanging, faEuroSign, faEarthAmerica, faHashtag } from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Metrics = () => {
  const { sites } = useAppSelector(selectSites)
  const isMultiSites = sites.length > 1
  const { filters, updateFilters } = useExploreFilters()
  const changeMetric = metric => updateFilters({ metric })
  const changeSite = event => {
    const selectedSite = event.target
    const siteName = sites.find(site => site.id === selectedSite.value)?.name
    const payload = selectedSite.value !== ALL_SITES
      ? [{ name: siteName, id: selectedSite.value }]
      : []
    updateFilters({ sites: payload })
  }
  const getColor = metric => filters.metric === metric ? 'secondary' : 'negative'
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const [_, setParams] = useSearchParams()

  useEffect(() => {
    if(filters.metric === METRICS.AVGWaste) {
      updateFilters({ metric: METRICS.totalWaste })
    }
  }, [])

  useEffect(() => {
    if(!isMultiSites) {
      updateFilters({ sites })
    }
  }, [sites])

  const buttonSize = isMobile ? 'extraSmall' : 'small';
  const iconSize = isMobile ? '2xl' : '2x';
  const minWidth = isMobile ? '40px' : '70px'

  return (
    <Stack direction="row" spacing={2} alignItems="center" flex={1} justifyContent="flex-end">
      {isMultiSites && !isMobile && (
        <FormControl variant="outlined" fullWidth sx={{ maxWidth: '280px' }}>
          <Select
            id="site"
            placeholder="All Sites"
            name="sites"
            value={filters.sites[0]?.id || ALL_SITES}
            onChange={changeSite}
          >
            <MenuItem value="all">All Sites</MenuItem>
            {sites.map(site => (
              <MenuItem key={site.id} value={site.id}>{site.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <Tooltip title={METRICS_LABEL[METRICS.totalWaste]}>
        <Button
          variant="contained"
          color={getColor(METRICS.totalWaste)}
          size={buttonSize}
          onClick={() => changeMetric(METRICS.totalWaste)}
          sx={{ minWidth }}
        >
          <FontAwesomeIcon size={iconSize} icon={faWeightHanging} />
        </Button>
      </Tooltip>
      <Tooltip title={METRICS_LABEL[METRICS.coastWaste]}>
        <Button
          variant="contained"
          color={getColor(METRICS.coastWaste)}
          size={buttonSize}
          onClick={() => changeMetric(METRICS.coastWaste)}
          sx={{ minWidth }}
        >
          <FontAwesomeIcon icon={faEuroSign} size={iconSize}/>
        </Button>
      </Tooltip>
      <Tooltip title={METRICS_LABEL[METRICS.wasteEmission]}>
        <Button
          variant="contained"
          color={getColor(METRICS.wasteEmission)}
          size={buttonSize}
          onClick={() => changeMetric(METRICS.wasteEmission)}
          sx={{ minWidth }}
        >
          <FontAwesomeIcon icon={faEarthAmerica} size={iconSize}/>
        </Button>
      </Tooltip>
      <Tooltip title={METRICS_LABEL[METRICS.wasteLogs]}>
        <Button
          variant="contained"
          color={getColor(METRICS.wasteLogs)}
          size={buttonSize}
          onClick={() => changeMetric(METRICS.wasteLogs)}
          sx={{ minWidth }}
        >
          <FontAwesomeIcon icon={faHashtag} size={iconSize}/>
        </Button>
      </Tooltip>
    </Stack>
  );
};

export default Metrics;
