import React from 'react';
import {Box, Tab } from "@mui/material";
import {TabContext, TabList, TabPanel} from "@mui/lab";

const Tabs = ({ tabs }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  if (!tabs?.length) {
    return null
  }

  return (
    <Box>
      <TabContext value={value}>
        <Box>
          <TabList onChange={handleChange} aria-label="Venus tabs">
            {tabs.map(({ title, Icon }, index) => (
              <Tab key={title} label={title} icon={ Icon && <Icon />} iconPosition="start" value={index} />
            ))}
          </TabList>
        </Box>
        {tabs.map(({Component, title}, index) => (
          <TabPanel key={title} value={index}>
            <Component />
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
};

export default Tabs;
