import React from 'react';
import {Box, Stack, useMediaQuery} from "@mui/material";
import Button from "@mui/material/Button";
import { DATE_GRAN, METRICS} from "../../constants/explore";
import {titleCase} from "../../utils/formaters/titleCase";
import {useExploreFilters} from "./useExploreFilters";
import {useSelector} from "react-redux";
import {selectExplore} from "../../redux/explore";

const TimeFilter = () => {
  const { filters, updateFilters } = useExploreFilters()
  const { data } = useSelector(selectExplore)
  const showTip = data?.chart?.length > 100
  const getColor = filter => filter === filters.dateGranularity ? 'secondary' : 'negative';
  const handleChange = filter => updateFilters({ dateGranularity: filter }, true)
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down("xxl"))
  const disabled = filter => filters.metric === METRICS.occupancy && filter === DATE_GRAN.hourly
  return (
    <Box position="relative">
      <Stack direction="row" spacing={2}>
        {Object.values(DATE_GRAN).map(filter => (
          <Button
            key={filter}
            onClick={() => handleChange(filter)}
            size={isTablet ? 'small' : 'medium'}
            variant="contained"
            color={getColor(filter)}
            disabled={disabled(filter)}
          >
            {titleCase(filter)}
          </Button>
        ))}
      </Stack>
    </Box>
  );
};

export default TimeFilter;
