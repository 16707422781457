import React from 'react';
import {styled} from "@mui/material/styles";
import {Stack, Typography} from "@mui/material";
import Button from "@mui/material/Button";

const Wrapper = styled(Stack)(() => ({
  backgroundColor: "white",
  borderRadius: '12px',
  padding: '14px 18px'
}))

const Pagination = ({ currentPage, totalPages, goNextPage, goPrevPage, canGoNext }) => {
  return (
    <Wrapper direction="row" justifyContent="space-between" alignItems="center">
      <Typography variant="textSm" fontWeight={500}>{`Page ${currentPage} of ${totalPages}`}</Typography>
      <Stack direction="row" spacing={2}>
        <Button onClick={goPrevPage} disabled={+currentPage === 1} size="small" color="secondary">Previous</Button>
        <Button onClick={goNextPage} disabled={!canGoNext} size="small" color="secondary">Next</Button>
      </Stack>
    </Wrapper>
  );
};

export default Pagination;