import React from 'react';
import {Stack, Typography} from "@mui/material";
import styled from "@emotion/styled";
import {Badge, DatePicker, Icon, Point} from "../../../../../ui";
import Placeholder from "../../../../../layouts/Placeholder";
import {displayEventDuration} from "../../helpers";
import useIsMobile from "../../../../../hooks/useIsMobile";
import Loader from "../../../../../ui/Loader";
import Popup from "../../../../../ui/Popup";
import EventPopup from "./EventPopup";

const ItemContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.colors.gray['100'],
  padding: '16px',
  borderRadius: '12px',
}))

const PLACEHOLDER_TITLE = 'No Upcoming Events'
const PLACEHOLDER_SUBTITLE = 'To add an event, please click the ‘Add Event’ button'

const EventItem = ({ event }) => {
  const isMobile = useIsMobile();
  const direction = isMobile ? 'column' : 'row';
  const align = isMobile ? 'flex-start' : 'center';
  return (
   <ItemContainer direction={direction} spacing={2} justifyContent="space-between" alignItems={align}>
     <Stack direction="row" alignItems="center" spacing={2}>
       <Point />
       <Typography fontWeight={500} variant="textSm">{event.name}</Typography>
     </Stack>
     <Badge
       color="info"
       label={displayEventDuration(event.start_date, event.end_date)}
       icon={<Icon icon="calendar" size='small' color="white" />}
       size='large'
     />
   </ItemContainer>
 )
}

const EventList = ({ events = [], isLoading, setDate }) => {
  const isMobile = useIsMobile();
  return (
    <Stack spacing={4} sx={{ flex: 1, minWidth: 0, height: '100%' }}>
      <Stack direction="row" justifyContent="space-between" spacing={4}>
        <Typography variant="textXl">Upcoming</Typography>
        {isMobile && <DatePicker onChange={setDate} placement="bottomEnd" />}
      </Stack>
      <Loader loading={isLoading}>
        {!!events.length ? (
          <Stack spacing={4}>
            {events.map((event) => (
              <EventItem event={event} key={event.id} />
            ))}
          </Stack>
        ) : <Placeholder title={PLACEHOLDER_TITLE} subTitle={PLACEHOLDER_SUBTITLE} />}
      </Loader>
    </Stack>
  );
};

export default EventList;
