import React, {createContext, useMemo} from 'react';

export const ModalContext = createContext({
  close: () => {},
  open: () => {},
  closeAll: () => {},
})

const ModalProvider = ({ children }) => {
  const [modals, setModals] = React.useState([]);
  const { Modal, props, id } = modals[modals.length - 1] || {}
  const open = modal => setModals(_ => [..._, modal]);
  const close = modalId => setModals(m => m.filter(({ id }) => id !== modalId))
  const closeAll = () => setModals([]);
  const value = useMemo(() => ({
    open,
    close,
    closeAll,
  }), [])
  return (
    <ModalContext.Provider value={value}>
      {children}
      {Modal && (
        <Modal {...props} />
      )}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
