import React from 'react';
import {Stack, Typography} from "@mui/material";

const Title = ({ children }) => (
  <Typography variant="displaySm" component="h1">{ children }</Typography>
)

const Actions = ({ children }) => (
  <Stack direction="row" spacing={4}>
    {children}
  </Stack>
)

const Header = ({ children }) => {
  return (
    <Stack direction="row" justifyContent='space-between' alignItems="center">
      {children}
    </Stack>
  );
};

Header.Title = Title;
Header.Actions = Actions;

export default Header;
