import {useQueryFilters} from "./useQueryFilters";
import {useEffect, useMemo} from "react";
import {ITEMS_PER_PAGE} from "../constants/base";

const defaultParams = {
  perPage: ITEMS_PER_PAGE,
  page: 1,
}
export const usePagination = ({ total, perPage = defaultParams.perPage }) => {
  const { filters, setFilters } = useQueryFilters({ page: defaultParams.page })
  const totalPages = total ? Math.ceil(total / perPage) : 1
  const currentPage = Math.min(filters.page || 1, totalPages)

  useEffect(() => {
    if (totalPages < filters.page) {
      setFilters({ page: totalPages })
    }
  }, [totalPages, filters.page]);

  const goNextPage = () => setFilters({ page: +currentPage + 1 })
  const goPrevPage = () => setFilters({ page: Math.max(+currentPage - 1, 1) })

  return useMemo(() => ({
    totalPages,
    currentPage,
    goNextPage,
    goPrevPage,
    canGoNext: totalPages > currentPage,
    limit: perPage,
    offset: perPage * currentPage - perPage,
  }), [currentPage, totalPages, goNextPage, goPrevPage])
}