import React from 'react';
import Button from "@mui/material/Button";
import { Stack } from "@mui/material";
import {CHART_TYPE} from "../../constants/explore";
import {useExploreFilters} from "./useExploreFilters";
import { faChartSimple, faChartLine, faTableList } from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ChartView = () => {
  const { filters, updateFilters } = useExploreFilters()
  const chartType = filters.chartType
  const changeCartType = type => updateFilters({chartType: type})
  const getColor = type => chartType === type ? 'secondary' : 'negative'
  return (
    <Stack direction="row" spacing={2}>
      <Button
        variant="contained"
        color={getColor(CHART_TYPE.bar)}
        size="small"
        onClick={() => changeCartType(CHART_TYPE.bar)}
      >
        <FontAwesomeIcon icon={faChartSimple} size="2x" />
      </Button>
      <Button
        variant="contained"
        color={getColor(CHART_TYPE.line)}
        size="small"
        onClick={() => changeCartType(CHART_TYPE.line)}
      >
        <FontAwesomeIcon icon={faChartLine} size="2x" />
      </Button>
      <Button
        variant="contained"
        color={getColor(CHART_TYPE.table)}
        size="small"
        onClick={() => changeCartType(CHART_TYPE.table)}
      >
        <FontAwesomeIcon icon={faTableList} size="2x" strokeWidth={1} />
      </Button>
    </Stack>
  );
};

export default ChartView;
