import React from 'react';
import {displayEventDate} from "../../helpers";
import {Icon, Pagination, Table} from "../../../../../ui";
import {EVENT_TYPE_LABELS} from "../../constants";
import {Stack} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {useTheme} from "@mui/material/styles";
import {useModal} from "../../../../../hooks/useModal";
import AddEvent from "../../modals/AddEvent";
import {snakeToCamel} from "../../../../../utils/formaters/camelCase";
import {useDeleteEventModal} from "../../hooks/useDeleteEvent";
import Placeholder from "../../../../../layouts/Placeholder";

const ActionCell = ({ data }) => {
  const { colors } = useTheme()
  const { open: openEditModal } = useModal(AddEvent)
  const deleteEvent = useDeleteEventModal(data.id)

  const handleEdit = () => {
    openEditModal({
      title: 'Edit Event',
      data,
    })
  }

  return (
    <Stack direction="row" spacing={2}>
      <IconButton sx={{ flexShrink: 0 }} onClick={handleEdit}>
        <Icon size="small" icon='pencil' />
      </IconButton>
      <IconButton onClick={deleteEvent}>
        <Icon size="small" color={colors.red.primary} icon="trashBin" />
      </IconButton>
    </Stack>
  )
}

const columns = [
  {
    field: 'name',
    headerName: 'Event Name',
    flex: 3,
    sortable: false,
    minWidth: 200,
    id: 'name',
  },
  {
    field: 'occupancy_type',
    headerName: 'Event Type',
    flex: 2,
    valueFormatter: ({ value }) => EVENT_TYPE_LABELS[value],
    sortable: false,
    minWidth: 150,
    id: 'occupancy_type'
  },
  {
    field: 'estimate_occupancy',
    headerName: 'Occupancy',
    flex: 2,
    sortable: false,
    valueFormatter: ({ value }) => value > 250 ? '250+' : value,
    minWidth: 150,
    id: 'estimate_occupancy'
  },
  {
    field: 'start_date',
    headerName: 'Start Date',
    flex: 2,
    valueFormatter: ({ value }) => displayEventDate(value),
    sortable: false,
    minWidth: 120,
    id: 'start_date',
  },
  {
    field: 'end_date',
    headerName: 'End Date',
    flex: 2,
    valueFormatter: ({ value }) => displayEventDate(value),
    sortable: false,
    minWidth: 120,
    id: 'end_date',

  },
  {
    flex: 2,
    renderCell: ({ row }) => <ActionCell data={snakeToCamel(row)} />,
    sortable: false,
    type: 'number',
    minWidth: 120,
    id: 'action',
  },
];

const EventsTable = ({ data = [] }) => {
  if (!data.length) {
    return (
      <Placeholder
        title="Oops! No events to show"
        subTitle="No events yet. Stay tuned!"
      />
    )
  }
  return (
    <Table rows={data} columns={columns} />
  );
};

export default EventsTable;