import dayjs from "dayjs";

export const displayEventDuration = (startDate, endDate) => {
  const start = dayjs(startDate)
  const end = dayjs(endDate)
  if (start.day() === end.day()) {
    return `${start.format('DD/MM/YYYY HH:mm')} - ${end.format('HH:mm')}`
  }
  return `${start.format('DD/MM/YYYY HH:mm')} - ${end.format('DD/MM/YYYY HH:mm')}`
}

export const isSameDay = currentDate => date => dayjs(currentDate).isSame(dayjs(date), 'day')
export const displayEventDate = date => dayjs(date).format('DD/MM/YYYY')
export const displayEventTime = date => dayjs(date).format('DD/MM/YYYY HH:mm')