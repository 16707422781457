import React, {useMemo, useState} from 'react';
import {Area, Calendar, Point, Popup} from "../../../../ui";
import { Stack } from "@mui/material";
import EventList from "./components/EventList";
import useMobile from "../../../../hooks/useIsMobile";
import useSWR from "swr";
import {useParams} from "react-router-dom";
import {formatDataQuery} from "../../../../utils/date/formatDataQuery";
import {fetcher} from "../../../../utils/axios";
import {isSameDay} from "../helpers";
import {getPathFromParams} from "../../../../routes";
import {events as swrEvents} from "../../../../utils/swrs/urls";
import EventPopup from "./components/EventPopup";
import dayjs from "dayjs";

const getCalendarParams = date => ({
  month: date.getMonth() + 1,
  year: date.getFullYear(),
})

const EVENTS_LIMIT = 5

const renderCell = events => date => {
  const currentEvents = events.filter(isSameDay(date))
  return !!currentEvents.length ? <Point size="small" /> : null
}

const UpcomingEvents = () => {
  const isMobile = useMobile();
  const { siteId } = useParams();
  const [date, setDate] = useState(new Date());
  const params = useMemo(() => ({
    start_date: formatDataQuery(date),
    limit: EVENTS_LIMIT,
    ordering: 'start_date',
  }), [])
  const keyParams = { site: siteId };
  const {data, isLoading } = useSWR(
    [getPathFromParams(swrEvents.baseUrl, keyParams), params],
    fetcher
  )
  const [showPopup, setShowPopup] = useState(false)

  const {data: events, isLoading: isEventsLoading } = useSWR(
    [getPathFromParams(swrEvents.baseUrl, keyParams), { date: formatDataQuery(date),  ordering: 'start_date',}],
    fetcher,
    { fallbackData: [] }
  )

  const calendarParams = useMemo(
    () => getCalendarParams(date),
    [date.getMonth(), date.getFullYear()]
  )
  const {data: monthEvents } = useSWR(
    [getPathFromParams(swrEvents.calendarEvents, keyParams), calendarParams],
    fetcher
  )
  const handleDateChange = date => {
    setDate(date)
    setShowPopup(!!monthEvents?.dates?.some(eventDate => dayjs(eventDate).isSame(dayjs(date))))
  };

  return (
    <Area style={{ minHeight: '300px', display: 'flex' }}>
      <Stack direction="row" spacing={6} sx={{ flex: 1, minWidth: 0 }}>
        <EventList events={data?.results} isLoading={isLoading} setDate={handleDateChange} />
        {!isMobile && (
          <Popup
            enabled={!isMobile}
            isOpen={showPopup}
            placement="left-start"
            renderPopup={({ close }) => <EventPopup close={close} events={events} isLoading={isEventsLoading} />}
          >
            <Calendar onChange={handleDateChange} renderCell={renderCell(monthEvents?.dates || [])} />
          </Popup>
        )}
      </Stack>
    </Area>
  );
};

export default UpcomingEvents;
