import {useModal} from "../../../../hooks/useModal";
import Confirm from "../../../../modals/Confirm";
import {DELETE_MODAL} from "../constants";
import {mutate} from "swr";
import {getKey} from "../../../../utils/swrs/helpers";
import {getPathFromParams} from "../../../../routes";
import {events} from "../../../../utils/swrs/urls";
import {getErrorMessage} from "source-map-explorer/lib/app-error";
import useSWRMutation from "swr/mutation";
import {fetchMutation} from "../../../../utils/axios";
import {useParams} from "react-router-dom";
import {useSnackbar} from "notistack";
import {useCallback} from "react";

export const useDeleteEventModal = (eventId) => {
  const { open } = useModal(Confirm, { withCloseButton: true });
  const { siteId } = useParams();
  const { trigger } = useSWRMutation(
    getPathFromParams(events.updateEvents, { id: eventId, site: siteId }),
    fetchMutation.delete
  )
  const { enqueueSnackbar } = useSnackbar()


  const handleDelete = () => {
    open({
      title: DELETE_MODAL.title,
      description: DELETE_MODAL.description,
      confirmText: DELETE_MODAL.delete,
      rejectText: DELETE_MODAL.cancel,
      onConfirm: trigger,
    })
      .then(result => {
        if (!!result) {
          enqueueSnackbar(DELETE_MODAL.successAlert, { variant: 'success' });
          close()
          mutate(getKey(getPathFromParams(events.baseUrl, { site: siteId })))
        }
      })
      .catch((error) => enqueueSnackbar(getErrorMessage(error), { variant: 'error' }))
  }

  return useCallback(handleDelete, [eventId, siteId])
}