import styled from "@emotion/styled";
import { keyframes } from '@emotion/react';

const l7 = keyframes`
    to{transform: rotate(.5turn)}
`

import React from 'react';
const Loader = styled.div`
    width: 50px;
    height: 50px;
    aspect-ratio: 1;
    --_c:${({ theme }) => `no-repeat radial-gradient(farthest-side,${theme.colors.yellow.primary} 92%,#0000)`};
    background:
            var(--_c) top,
            var(--_c) left,
            var(--_c) right,
            var(--_c) bottom;
    background-size: 12px 12px;
    animation: ${l7} 1s infinite;
`

const Spinner = () => {
  return <Loader />;
};

export default Spinner;