import React, {useEffect, useMemo, useRef } from "react";
import {
  Paper,
  BottomNavigationAction,
  BottomNavigation as BottomNavigationMui, Stack, Slide, Popover, Typography
} from "@mui/material";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {useTheme} from "@mui/material/styles";
import {COLORS} from "../../constants/base";
import IconButton from "@mui/material/IconButton";
import { matchPath } from "react-router"
import styled from "@emotion/styled";
import SubmenuProvider, {useSubMenu} from "../sidebar/SubmenuProvider";
import useClickOutside from "../../hooks/useClickOutside";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {isFunc} from "../../utils/funcs";
import {Icon} from "../../ui";
import {useSelector} from "react-redux";
import {selectSelectedSite} from "../../redux/sites";


const SubmenuContainer = styled(Paper)`
  position: absolute;
    width: 100vw;
    min-height: 200px;
    bottom: 100%;
    left: 0;
    background: ${({ theme}) => theme.sidebar.submenu.background};
    border-radius: 0;
    border-top-right-radius: 40px;
    border-top-left-radius: 40px;
    padding: 20px 20px;
`


const NavLink = ({ page: { icon, href, children, title, redesign } }) => {
  const navigate = useNavigate();
  const { setRoutes, setTitle, title: parentTitle } = useSubMenu()
  const { pathname, search } = useLocation()
  const { siteId } = useParams()
  const site = useSelector(selectSelectedSite(siteId))
  const selectedSite = siteId || site?.id
  const path = isFunc(href) ? href(selectedSite) : href
  const isActive = useMemo(() => {
    const childrenLinks = children?.map(page => page.href) || []
    return [...childrenLinks, path]?.some(link => matchPath(link, pathname))
  }, [pathname, href])
  const hasSubpage = !!children?.length;
  const handleClick = () => {
    if(title === parentTitle) {
      setRoutes([])
      return;
    }
    if (hasSubpage) {
      setRoutes(children)
      setTitle(title)
      return
    }
    navigate({ pathname: `/${path}`, search })
  }
  return (
    <Stack direction="row" sx={{ flex: 1 }} justifyContent="center" alignItems="center">
      <IconButton onClick={handleClick} sx={{
        color: 'white',
        background: isActive ? `${COLORS.activeBlue} !important` : 'transparent',
        width: '40px',
        height: '40px'
      }}>
        {redesign
          ? <Icon icon={icon} color="white" />
          :  <FontAwesomeIcon icon={icon} fill="white" color="white" />
        }
      </IconButton>
    </Stack>
  )
}

const LinkItem = ({ icon, title, href }) => {
  const { search } = useLocation()

  return  (
    <Link to={{ pathname: href, search }} style={{ textDecoration: 'none' }}>
      <Stack direction="row" alignItems="center" spacing={2}>
        {icon && <FontAwesomeIcon icon={icon} color={COLORS.accentBlue} />}
        <Typography variant="subtitle" fontWeight={400} color="primary">{title}</Typography>
      </Stack>
    </Link>
  )
}

const NestedList = ({ menuRef }) => {
  const { routes, setRoutes, title } = useSubMenu()
  const { pathname } = useLocation()
  const { siteId } = useParams()
  const site = useSelector(selectSelectedSite(siteId))
  const selectedSite = siteId || site?.id
  const ref = useRef(null)
  useClickOutside([ref, menuRef], () => setRoutes([]))

  useEffect(() => {
    setRoutes([])
  }, [pathname ]);

  return(
    <Slide direction="up" in={!!routes.length} container={ref.current} timeout={routes.length ? 200 : 0}>
      <SubmenuContainer ref={ref}>
        <Typography variant="subtitle" color="primary" fontWeight={700}>{title}</Typography>
        <Stack>
          {routes.map(({ href, ...route}) => (
            <LinkItem
              key={route.title}
              {...route}
              href={isFunc(href) ? href(selectedSite) : href}
            />
          ))}
        </Stack>
      </SubmenuContainer>
    </Slide>
  )
}

function MyBottomNavigation({ items }) {
  const theme = useTheme()
  const menuRef = useRef(null)

  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 1000 }}
      elevation={3}
    >
      <SubmenuProvider>
        <BottomNavigationMui ref={menuRef} sx={{ background: theme.sidebar.background}}>
          {items.map((item) => (
            <BottomNavigationAction
              key={item.title}
              component={() => <NavLink page={item} />}
            />
          ))}
        </BottomNavigationMui>
        <NestedList menuRef={menuRef} />
      </SubmenuProvider>
    </Paper>
  );
}

export default MyBottomNavigation;
