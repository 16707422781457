import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";

const useToSearchParams = () => {
  const [searchParams] = useSearchParams();

  const toSearchParams = (props) => {
    const newSP = { ...Object.fromEntries(searchParams.entries()) };

    if (props.site && props.site.length) {
      newSP.siteId = props.site.map((item) => item.id);
      newSP.siteName = props.site.map((item) => item.name);
    } else {
      delete newSP.siteId;
      delete newSP.siteName;
    }
    if (props.zone && props.zone.length) {
      newSP.zoneId = props.zone.map((item) => item.id);
      newSP.zoneName = props.zone.map((item) => item.name);
      newSP.zoneSerial = props.zone.map((item) => item.serial);
    } else {
      delete newSP.zoneId;
      delete newSP.zoneName;
      delete newSP.zoneSerial;
    }
    if (props.food && props.food.length) {
      newSP.foodId = props.food.map((item) => item.id);
      newSP.foodName = props.food.map((item) => item.name);
    } else {
      delete newSP.foodId;
      delete newSP.foodName;
    }
    if (props.startDate) {
      newSP.startDate = dayjs(props.startDate).format("YYYY-MM-DD");
    } else {
      delete newSP.startDate;
    }
    if (props.endDate) {
      newSP.endDate = dayjs(props.endDate).format("YYYY-MM-DD");
    } else {
      delete newSP.endDate;
    }
    if (props.ordering) {
      newSP.ordering = props.ordering.id;
    } else {
      delete newSP.ordering;
    }
    if (props.display) {
      newSP.display = props.display.id;
    } else {
      delete newSP.display;
    }
    if (props.group && props.group.length) {
      newSP.group = props.group.map((item) => item.id);
    } else {
      delete newSP.group;
    }
    if (props.role) {
      newSP.role = props.role;
    } else {
      delete newSP.role;
    }
    if (props.status) {
      newSP.status = props.status;
    } else {
      delete newSP.status;
    }
    if (props.autoLabel) {
      newSP.autoLabel = props.autoLabel;
    } else {
      delete newSP.autoLabel;
    }
    if (props.trimmings) {
      newSP.trimmings = props.trimmings;
    } else {
      delete newSP.trimmings;
    }
    if (props.secondaryWaste) {
      newSP.secondaryWaste = props.secondaryWaste;
    } else {
      delete newSP.secondaryWaste;
    }
    if (props.nonFood) {
      newSP.nonFood = props.nonFood;
    } else {
      delete newSP.nonFood;
    }
    if (props.event) {
      newSP.event = props.event;
    } else {
      delete newSP.event
    }

    return newSP;
  };

  return toSearchParams;
};

export default useToSearchParams;
