import React, {
  useEffect,
  useRef,
  useState,
} from "react";
import { styled } from "@mui/material/styles";
import DatePickerRs from "rsuite/DatePicker";
import CustomProvider from "rsuite/CustomProvider";
import { deDE, enGB } from 'rsuite/locales'
import PropTypes from "prop-types";
import {Box, FormControl, FormHelperText, InputLabel} from "@mui/material";
import GlobalStyles from "@mui/material/GlobalStyles";


import "rsuite/dist/rsuite-no-reset.min.css";
import { useLang } from "../../hooks/useLang";
import { safeCall } from "../../utils/funcs";
import {handleWith} from "../../utils/handleWith";

const LOCALES_MAP = {
  'en': enGB,
  'de': deDE,
}

const styles = theme =>  ({
  border: `1px solid ${theme.colors.gray['200']}`,
  '&:hover:not(:disabled)': {
    borderColor: `${theme.colors.yellow.primary} !important`
  },
  borderRadius: '8px',
  "&.rs-picker-has-value .rs-picker-toggle-value": {
  },
  "& svg": {
    fontSize: "16px",
    margin: "2px -2px",
    top: '14px !important'
  },
  "& .rs-picker-toggle": {
    boxShadow: "none",
    background: 'none',
    borderRadius: '8px',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  '& .rs-picker-toggle-clean': {
    top: '16px !important'
  },
  '& .rs-input-group-inside': {
    borderRadius: '8px'
  },
  '& .rs-input-group-inside .rs-input-group-addon': {
    paddingRight: '14px',
    background: 'transparent',
  },
  '& .rs-input-group-inside:hover': {
    borderColor: 'transparent',
  },
  '& .rs-input-group input': {
    borderRadius: '8px',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '14px',
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '1rem',
  },
  '& .rs-input-group:focus-within': {
    borderColor: theme.colors.yellow.primary,
    outline: 'none',
    boxShadow: '0px 0px 0px 4px rgba(255, 246, 220, 1), 0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
  },
})

const StyleDateRangePicker = styled(DatePickerRs)(({ theme }) => ({
  "&.react-datepicker-popper": {
    zIndex: 1111,
  },
  cursor: 'pointer',
  ...styles(theme),
  "& .rs-picker-toggle:hover": {
    backgroundColor: "transparent",
  },
  "& .rs-picker-toggle-textbox": {
    paddingLeft: "12px",
  },
  "& .rs-picker-toggle-active": {
    backgroundColor: "transparent",
  },
}));

const DatePicker = ({
  value,
  onChange,
  fullWidth,
  label,
  onClean,
  placement,
  cleanable = false,
  error,
  helperText,
}) => {
  const ref = useRef(null);
  const { lang, getLocale } = useLang();
  const [date, setDate] = useState(value);

  return (
    <>
      <GlobalStyles
        styles={(theme) => ({
          ".rs-picker-menu": {
            zIndex: 1301,
          },
          ".rs-picker-toolbar > .rs-stack-item:last-child": {
            display: "none",
          },
          ".rs-picker-menu .rs-stack-item .rs-picker-toolbar-ranges": {
            maxWidth: "468px",
          },
          ".rs-picker-daterange-menu .rs-picker-daterange-panel-show-one-calendar \
            .rs-stack-item .rs-picker-toolbar-ranges": {
            width: "inherit",
          },
          '.rs-picker-popup-daterange': {
            zIndex: 1302,
          },
          '.rs-picker-popup .rs-picker-toolbar-ranges': {
            maxWidth: '450px',
          },
          '.rs-calendar-table-cell-content': {
            borderRadius: '50%',
          },
          '.rs-calendar-table-cell': {
            '&:hover .rs-calendar-table-cell-content': {
              background: `${theme.colors.yellow.light} !important`,
              color: `${theme.colors.yellow.dark} !important`,
            },
            '&.rs-calendar-table-cell-selected .rs-calendar-table-cell-content': {
              background: `${theme.colors.yellow.primary} !important`,
              color: `white !important`,
            },
            '&.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content': {
              background: theme.colors.gray['400'],
              color: theme.colors.gray.primary,
              boxShadow: 'none !important',
            },
          },
          '.rs-picker-popup.rs-picker-popup-date': {
            zIndex: 9999,
          },
          '.Mui-error + .rs-picker': {
            borderColor: theme.colors.red.primary,
          }
        })}
      />
        <FormControl fullWidth={fullWidth} ref={ref} variant='outlined' error={error}>
          {label && <InputLabel>{label}</InputLabel>}
          <CustomProvider locale={LOCALES_MAP[lang]}>
            <Box
              component={StyleDateRangePicker}
              value={date}
              onChange={handleWith(setDate, safeCall(onChange))}
              format="dd/MM/yyyy"
              oneTap
              block={fullWidth}
              onClean={onClean}
              cleanable={cleanable}
              placeholder={getLocale('Select Date')}
              placement={placement}
            />
          </CustomProvider>
          {helperText && (
            <FormHelperText color="error">{helperText}</FormHelperText>
          )}
        </FormControl>
    </>
  );
}

DatePicker.propTypes = {
  value: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  onClear: PropTypes.func,
  label: PropTypes.string,
};

export default DatePicker;
