import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc);
dayjs.extend(timezone)

const timezoneRegex = /[+-]\d{2}:\d{2}$/

export const extractTimeZone = value => {
  const match = value.match(timezoneRegex)
  if (!match) {
    return {
      timeZone: 'UTC',
      date: value.replace('Z', '')
    }
  }
  const timezone = match[0];
  return {
    timeZone: `GMT${timezone}`,
    date: value.replace(timezoneRegex, '')
  }
}

export const localTimeFormat = (value) => {
  if (value) {
    const noTimeZone = value.replace(/(([+-]\d{2}:\d{2})|Z)$/, '')
    const date = dayjs(noTimeZone);
    return date.format("h:mm:ss A");
  }
  return null;
};

export const localTime = (value) => {
  const {timeZone, date} = extractTimeZone(value)
  return `${dayjs(date).format('HH:mm:ss')} ${timeZone}`
};

export const trimTimeZone = date => date.replace(/(([+-]\d{2}:\d{2})|Z)$/, '')

export const getDateInTimezone = timezone => date => new Date(trimTimeZone(dayjs(date).tz(timezone).format()))
export const getTimezoneUtc = timezone => {
  return date => dayjs.tz(trimTimeZone(dayjs(date).format()), timezone).format()
}

export const testDate = date => dayjs.tz(date, 'America/New_York')