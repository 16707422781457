export const DELETE_MODAL = {
  title: 'Delete Event',
  description: 'Are you sure you want to delete event? This action cannot be undone.',
  cancel: 'Cancel',
  delete: 'Delete',
  successAlert: 'Event deleted successfully',
}

export const EVENT_TYPES = {
  HOLIDAY: 'HOLIDAY',
  EVENT: 'EVENT',
  EXPO: 'EXPO',
  CONFERENCE: 'CONFERENCE',
  OTHER: 'OTHER',
}

export const EVENT_TYPE_LABELS = {
  [EVENT_TYPES.HOLIDAY]: 'Holiday',
  [EVENT_TYPES.EVENT]: 'Event',
  [EVENT_TYPES.EXPO]: 'Expo',
  [EVENT_TYPES.CONFERENCE]: 'Conference',
  [EVENT_TYPES.OTHER]: 'Other',
}

export const EVENTS_OPTIONS = [
  {
    label: EVENT_TYPE_LABELS[EVENT_TYPES.HOLIDAY],
    value: EVENT_TYPES.HOLIDAY,
  },
  {
    label: EVENT_TYPE_LABELS[EVENT_TYPES.EVENT],
    value: EVENT_TYPES.EVENT,
  },
  {
    label: EVENT_TYPE_LABELS[EVENT_TYPES.EXPO],
    value: EVENT_TYPES.EXPO,
  },
  {
    label: EVENT_TYPE_LABELS[EVENT_TYPES.CONFERENCE],
    value: EVENT_TYPES.CONFERENCE,
  },
  {
    label: EVENT_TYPE_LABELS[EVENT_TYPES.OTHER],
    value: EVENT_TYPES.OTHER,
  },
]

export const ADD_EDIT_EVENT_MODAL = {
  successCreated: 'Event successfully created',
  successUpdated: 'Event successfully updated',
}

export const INITIAL_EVENTS_FILTERS = {
  startDate: '',
  endDate: '',
  types: [],
  minOccupancy: 0,
  maxOccupancy: 1000,
}

export const CONFIRM_UPDATE_EVENT = {
  title: 'Select your occupancy preference',
  description: 'Should the event occupancy replace the BAU occupancy for your selected day?',
  add: ' Add event occupancy to BAU occupancy',
  replace: 'Replace BAU occupancy with event occupancy',
  descriptionTip: 'You are inputting occupancy for this event. If you already have \'Business As Usual\' (BAU) occupancy set, you can add this event occupancy to the BAU occupancy amount or you can replace it so the event occupancy is the only occupancy for that day. Which would you like to do? '
}