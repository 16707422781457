import * as Yup from 'yup';
import { MAX_THRESHOLD } from '../../constants/widgets';
import dayjs from "dayjs";

export const INVALID_TIME = 'Invalid time';

export const required = schema => schema.required('Required field')

export const onlyIntegers = Yup.number().typeError('Should be a number').min(0, 'Should be positive').integer("Should be an integer")
export const validThreshold = Yup.number("number")
.max(MAX_THRESHOLD, `Threshold can not be more than ${MAX_THRESHOLD}`)
.required(`Threshold should be a number from 0.001 to ${MAX_THRESHOLD}`)

export const requiredText = Yup.string().required('Required field')
export const date = Yup.date().typeError('Invalid date')
export const time = Yup.date().typeError(INVALID_TIME)

export const endDate = date.nullable()
  .test("is-greater", "The end date should be after the start date or the same", function (value) {
    const { startDate } = this.parent;
    if (!value || !startDate) {
      return true
    }
    const start = dayjs(startDate);
    const end = dayjs(value);

    return end.isAfter(start) || end.isSame(start, 'day');
  })
