import React from 'react';
import {styled as muiStyled, useTheme} from "@mui/material/styles";
import {Stack, Typography} from "@mui/material";
import {NavLink} from "react-router-dom";
import Icon from "../Icon";

const Container = muiStyled(Stack)(({ theme }) => ({
  backgroundColor: theme.colors.gray['50'],
  width: '100%',
  padding: '4px',
  borderRadius: '8px',
}))

const Link = muiStyled(NavLink)(({ theme }) => ({
  textDecoration: 'none',
  padding: '6px 8px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: `${theme.colors.yellow.light} !important`,
  },
  transition: .3,
  fontWeight: 600,
  gap: '5px',
}))

const TabLinks = ({ links }) => {
  const { colors } = useTheme()
  return (
    <Container direction="row" spacing={2}>
      {links.map(({ path, icon, title }) => (
        <Link
          to={path}
          key={path}
          style={({ isActive }) => ({
            backgroundColor: isActive ? colors.yellow.primary : colors.gray['50'],
            color: isActive ? 'white' : colors.gray.primary,
          })}
        >
          {icon && (
            <Icon icon={icon} />
          )}
          <Typography variant='textSm'>{title}</Typography>
        </Link>
      ))}
    </Container>
  );
};

export default TabLinks;
