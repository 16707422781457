import React from 'react';
import Modal from "../../../../../ui/Modal";
import Form from "../../../../../layouts/Form";
import {Stack, TextField} from "@mui/material";
import {DatePicker, Select } from "../../../../../ui";
import {EVENTS_OPTIONS} from "../../constants";
import Button from "@mui/material/Button";
import useIsMobile from "../../../../../hooks/useIsMobile";
import {Formik} from "formik";
import * as Yup from 'yup'
import {onlyIntegers, endDate, date} from "../../../../../utils/validation/base";

const validation = Yup.object().shape({
  minOccupancy: onlyIntegers.optional(),
  maxOccupancy: onlyIntegers
    .test("is-greater", "Should be greater than min occupancy", function (value) {
      const { minOccupancy } = this.parent;
      if (!value) {
        return true
      }
      return value > minOccupancy
    }),
  endDate,
  startDate: date.nullable(),
})

const Filter = ({ close, bind, modalProps, ...props }) => {
  const isMobile = useIsMobile()
  const { filters } = modalProps
  const direction = isMobile ? 'column' : 'row'
  const applyFilters = values => bind(values)
  return (
    <Modal {...props} onClose={close}>
      <Modal.Title>Filters</Modal.Title>
      <Formik initialValues={filters} onSubmit={applyFilters} validationSchema={validation}>
        {({ handleChange, handleSubmit, values, errors, touched, setFieldValue }) => (
          <>
            <Modal.Content>
              <Form>
                <Stack direction={direction} spacing={2}>
                  <DatePicker
                    cleanable
                    onClean={() => setFieldValue('startDate', null, true)}
                    label="Start Date"
                    value={values.startDate}
                    name="startDate"
                    onChange={value => setFieldValue('startDate', value, true)}
                    error={!!errors.startDate && touched.startDate}
                    helperText={touched.startDate && errors.startDate}
                  />
                  <DatePicker
                    cleanable
                    onClean={() => setFieldValue('endDate', null, true)}
                    label="End Date"
                    value={values.endDate}
                    name="endDate"
                    onChange={value => setFieldValue('endDate', value, true)}
                    error={!!errors.endDate && touched.endDate}
                    helperText={touched.endDate && errors.endDate}
                  />
                </Stack>
                <Select
                  value={values.types}
                  label="Event Type"
                  options={EVENTS_OPTIONS}
                  multi
                  onChange={handleChange}
                  name="types"
                  placeholder="All"
                />
                <Stack spacing={2} direction={direction}>
                  <TextField
                    sx={{ flex :1 }}
                    fullWidth
                    label="Min Occupancy"
                    name="minOccupancy"
                    value={values.minOccupancy}
                    onChange={handleChange}
                    type="number"
                    error={!!errors.minOccupancy && touched.minOccupancy}
                    helperText={touched.minOccupancy && errors.minOccupancy}
                  />
                  <TextField
                    sx={{ flex: 1 }}
                    label="Max Occupancy"
                    name="maxOccupancy"
                    value={values.maxOccupancy}
                    onChange={handleChange}
                    type="number"
                    error={!!errors.maxOccupancy && touched.maxOccupancy}
                    helperText={touched.maxOccupancy && errors.maxOccupancy}
                  />
                </Stack>
              </Form>
            </Modal.Content>
            <Modal.Footer>
              <Modal.Actions>
                <Modal.Action>
                  <Button fullWidth onClick={close} color="secondary">Cancel</Button>
                </Modal.Action>
                <Modal.Action>
                  <Button onClick={handleSubmit} fullWidth>Apply</Button>
                </Modal.Action>
              </Modal.Actions>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default Filter;