import {useEffect, useMemo, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import qs from "qs";
import {pipe} from "../utils/pipe";
import isEmpty from "lodash.isempty";
import {ensureDateRange, formatDayRangeToRequest} from "../utils/data/formatPayload";

const removeEmpty = obj => Object.fromEntries(
  Object.entries(obj).filter(([_, v]) => v !== '')
)

const trimUnsupportedFilters = supportedFilters =>  filters => {
  const filteredKeys = Object.keys(filters).filter(key => key in supportedFilters);
  return filteredKeys.reduce((filtered, key) => {
    filtered[key] = filters[key];
    return filtered;
  }, {});
}

export const useQueryFilters = (defaultFilters) => {
  const { search } = useLocation();
  const prepareFilters = trimUnsupportedFilters(defaultFilters);
  const [filters, setStateFilters] = useState(qs.parse(search.replace('?', '')));
  const init = useRef(false)
  const navigate = useNavigate();

  useEffect(() => {
    const queryFilters = qs.parse(search.replace('?', ''))
    if (init.current) {
      setStateFilters(queryFilters);
    }
    init.current = true;
  }, [search]);

  const setFilters = (newFilters) => {
    pipe(
      {...filters, ...newFilters},
      removeEmpty,
      formatDayRangeToRequest,
      f => isEmpty(f)
        ? navigate({ search: ''})
        : navigate({ search: qs.stringify(f)})
    )
  }

  return useMemo(() => ({
    filters: pipe(
      {...defaultFilters, ...filters},
      prepareFilters,
      ensureDateRange,
      ),
    setFilters,
  }), [filters, defaultFilters])
}
