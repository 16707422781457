import {Box, Dialog, DialogContent, Divider, IconButton, Stack } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import Title from "./Title";
import styled from "@emotion/styled";

const SPACING = 6

const FooterContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.colors.gray['50'],
}))

const StyledDialog = styled(Dialog)(({ theme, side }) => ({
  '& .MuiDialog-container': {
    justifyContent: side ? 'flex-end' : 'center',
    alignItems: side ? 'stretch' : 'center',
    '& .MuiPaper-root': {
      margin: '14px',
      maxHeight: 'calc(100% - 28px)',
    }
  }
}))

const Footer = ({ children }) => (
  <FooterContainer spacing={SPACING}>
    <Divider />
    <DialogContent>
      {children}
    </DialogContent>
  </FooterContainer>
)

const Content = ({ children}) => {
  return (
    <Stack sx={{ flex: 1 }} spacing={SPACING}>
      <Divider />
      <DialogContent>
        {children}
      </DialogContent>
    </Stack>
  );
};

const Actions = ({ children }) => (
  <Stack direction="row" alignItems="center" spacing={2}>
    {children}
  </Stack>
)

const Action = ({ children }) => (
  <Box flex={1}>
    {children}
  </Box>
)

const Modal = ({ onClose, children, ...props }) => {
  return (
    <StyledDialog onClose={onClose} {...props} open maxWidth='xs' fullWidth>
      <Stack sx={{ height: '100%' }}>
        {children}
      </Stack>
      {onClose && (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </StyledDialog>
  )
}

Modal.Footer = Footer
Modal.Title = Title
Modal.Content = Content
Modal.Actions = Actions
Modal.Action = Action

export default Modal;
