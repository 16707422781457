import {formatDataQuery} from "../date/formatDataQuery";

export const formatDayRangeToRequest = ({ startDate, endDate, ...rest } = {}) => ({
  ...(startDate ? { startDate: formatDataQuery(startDate)} : {}),
  ...(endDate ? { endDate: formatDataQuery(endDate)} : {}),
  ...rest,
})

export const ensureDateRange = ({ startDate, endDate, ...rest}) => ({
  ...(startDate ? { startDate: new Date(startDate)} : {}),
  ...(endDate ? { endDate: new Date(endDate)} : {}),
  ...rest,
})