import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Outlet } from "react-router-dom";
import { Box, CssBaseline, Paper as MuiPaper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { spacing } from "@mui/system";
import PropTypes from "prop-types";

import GlobalStyle from "../components/GlobalStyle";
import Navbar from "../components/navbar/Navbar";
import dashboardItems from "../components/sidebar/dashboardItems";
import Sidebar from "../components/sidebar/Sidebar";
import useMixpanelTrack from "../hooks/useMixpanelTrack";
import BottomNavigation from "../components/bottomNavigation/BottomNavigation";
import {withScrollToTop} from "../hoc/withScrollToTop";
import SubmenuProvider from "../components/sidebar/SubmenuProvider";

const drawerWidth = 258;
const drawerWidthMin = 68;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const openedMixin = () => ({
  width: `${drawerWidth}px`,
  transition: "width 0.5s",

  "& .MuiPaper-root": {
    width: `${drawerWidth}px !important`,
    transition: "width 0.5s",
  },
});

const closedMixin = (theme) => ({
  width: `${drawerWidthMin}px`,
  transition: "width 0.5s",

  "& .MuiPaper-root": {
    width: `${drawerWidthMin}px !important`,
    transition: "width 0.5s",
    backgroundColor: `${theme.sidebar.background} !important`,

    "& svg": {
      marginRight: 0,
      transition: "margin-right 0.5s",
    },
  },
});

const Drawer = styled(Box)(({ theme, toggle }) => ({
  backgroundColor: `${theme.sidebar.background} !important`,

  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,

    ...(toggle && openedMixin(theme)),
    ...(!toggle && closedMixin(theme)),
  },
}));

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
    min-width: 0;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
   background: ${(props) => props.theme.palette.background.default};
   padding-left: 0;
   padding-right: 0;
   padding-bottom: 80px; // padding 20 + woodchat 64 + 16

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }
`;

function BaseTemplate({ children }) {
  const mixpanelTrack = useMixpanelTrack();

  const [toggle, setToggle] = useState(true);
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (isMobile || isLgUp) {
      setMobileOpen(false);
    }
  }, [mobileOpen, isMobile, isLgUp]);

  const searchParams = new URLSearchParams(window.location.search);

  if (searchParams.get("utm_source")) {
    const utmContent = searchParams.get("utm_content");
    const utmCampaign = searchParams.get("utm_campaign");

    mixpanelTrack(`Navigate from ${utmContent} Report - ${utmCampaign}`);

    searchParams.delete("utm_source");
    searchParams.delete("utm_content");
    searchParams.delete("utm_campaign");

    window.history.replaceState(searchParams.toString(), "");
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleNavbarDrawerToggle = () => {
    handleDrawerToggle();
    mixpanelTrack("Navbar - Toggle Sidebar");
  };

  const handleSidebarDrawerToggle = () => {
    handleDrawerToggle();
    mixpanelTrack("Sidebar - Toggle Sidebar");
  };

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer id="drawer" toggle={1}>
        <Box
          sx={{
            display: { xs: "block", md: "block", lg: "block", xl: "none" },
          }}
        >
          <Sidebar
            PaperProps={{
              style: { width: toggle ? drawerWidth : drawerWidthMin },
              elevation: 8,
            }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleSidebarDrawerToggle}
            items={dashboardItems}
            toggle={toggle}
            setToggle={setToggle}
            showFooter
          />
        </Box>
        <Box sx={{ display: { xs: "none", md: "none", lg: "block" } }}>
          <Sidebar
            PaperProps={{
              style: { width: toggle ? drawerWidth : drawerWidthMin },
              elevation: 8,
            }}
            items={dashboardItems}
            showFooter
            toggle={toggle}
            setToggle={setToggle}
          />
        </Box>
        {isMobile && <BottomNavigation items={dashboardItems[0].pages} />}
      </Drawer>
      <AppContent>
        <Navbar
          isMobile={isMobile}
          isLgUp={isLgUp}
          onDrawerToggle={handleNavbarDrawerToggle}
          children={children}
        />
        <MainContent id="capture" px={isLgUp ? 12 : 4} elevation={0}>
          <Outlet />
          {/* {isMobile && <ChatwootWidget />} */}
        </MainContent>
      </AppContent>
    </Root>
  );
}

BaseTemplate.propTypes = {
  children: PropTypes.node,
};

BaseTemplate.defaultProps = {
  children: undefined,
};

export default withScrollToTop(BaseTemplate);
