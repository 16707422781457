import * as React from "react";
import {FormControl, InputLabel, ListSubheader, MenuItem, Select, Stack} from "@mui/material";
import { useLocation, useSearchParams } from "react-router-dom";

import constants from "./configs/constants";
import { useDispatch, useSelector } from "react-redux";
import { fetchSites, selectSites } from "../../redux/sites";
import { fetchZones, selectZones } from "../../redux/zones";
import ReviewSelectStandard from "../inputs/ReviewSelectStandard";
import { fetchFoods, selectFoods } from "../../redux/foods";
import { selectFilters, updateFilters } from "../../redux/filters";
import CustomButton from "../components/Buttons/CustomButton";
import {
  fetchParentGroups,
  selectParentGroups,
} from "../../redux/parentGroups";
import useToSearchParams from "../../hooks/useToSearchParams";
import formatInitialListState from "../../utils/formaters/formatInitialListState";
import DateInput from "../inputs/DateInput";
import {useMemo, useState} from "react";
import useSWR from "swr";
import {events as swrEvents} from "../../utils/swrs/urls";
import {fetcher} from "../../utils/axios";

function HeaderFilter() {
  const { pathname } = useLocation();

  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const toSearchParams = useToSearchParams();

  const { filters } = useSelector(selectFilters);
  const { parentGroups, isLoading: isLoadingGroup } =
    useSelector(selectParentGroups);
  const { sites, isLoading: isLoadingSites } = useSelector(selectSites);
  const { foods, isLoading: isLoadingFoods } = useSelector(selectFoods);
  const { zones, isLoading: isLoadingZones } = useSelector(selectZones);

  React.useEffect(() => {
    if (!isLoadingSites && sites.length === 0) {
      dispatch(fetchSites({ fields: ["id", "name"] }));
    }
    if (!isLoadingFoods && zones.length === 0) {
      dispatch(fetchZones());
    }
    if (!isLoadingZones && foods.length === 0) {
      dispatch(fetchFoods());
    }
  }, [dispatch]);

  const selectedSites = React.useMemo(() => {
    const siteList = formatInitialListState({
      ids: searchParams.getAll("siteId"),
      names: searchParams.getAll("siteName"),
    });
    return siteList.length ? siteList : filters.site;
  }, []);
  const selectedZones = React.useMemo(() => {
    const zoneList = formatInitialListState({
      ids: searchParams.getAll("zoneId"),
      names: searchParams.getAll("zoneName"),
      otherName: "serial",
      otherValue: searchParams.getAll("zoneSerial"),
    });
    return zoneList.length ? zoneList : filters.zone;
  }, []);
  const selectedFoods = React.useMemo(() => {
    const foodList = formatInitialListState({
      ids: searchParams.getAll("foodId"),
      names: searchParams.getAll("foodName"),
    });
    return foodList.length ? foodList : filters.food;
  }, []);
  const selectedGroups = React.useMemo(() => {
    const groupList = formatInitialListState({
      ids: searchParams.getAll("groupId"),
      names: searchParams.getAll("groupName"),
    });
    return groupList.length ? groupList : filters.group;
  }, []);

  const [site, setSite] = React.useState(selectedSites);
  const [zone, setZone] = React.useState(selectedZones);
  const [food, setFood] = React.useState(selectedFoods);
  const [startDate, setStartDate] = React.useState(filters.startDate);
  const [endDate, setEndDate] = React.useState(filters.endDate);
  const [group, setGroup] = React.useState(selectedGroups);
  const [role, setRole] = React.useState(filters.role);
  const [status, setStatus] = React.useState(filters.status);
  const [autoLabel, setAutoLabel] = React.useState(filters.autoLabel);
  const [event, setEvent] = useState(filters.event)

  React.useEffect(() => {
    dispatch(fetchZones());
    dispatch(fetchSites({ fields: ["id", "name", "parent_group"] }));
    dispatch(fetchFoods());
    dispatch(fetchParentGroups());
  }, [dispatch]);

  const params = React.useMemo(
    () => ({
      site,
      zone,
      food,
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      role,
      status,
      group,
      autoLabel,
      event,
    }),
    [site, zone, food, startDate, endDate, group, role, status, autoLabel, event]
  );

  const submitHandler = () => {
    const dates = {startDate: params.startDate, endDate: params.endDate}

    if (params.startDate > params.endDate) {
      const temp = params.startDate;
      setStartDate(params.endDate);
      setEndDate(temp);
      dates.startDate = endDate;
      dates.endDate = startDate;
    }

    dispatch(updateFilters({ ...filters, ...params, ...dates }));
    const searchParams = toSearchParams({...params, ...dates});
    setSearchParams(searchParams);
  };

  const isSitePage = pathname.includes("site");
  const isReviewPage = pathname.includes("review");
  const isUserPage = pathname.includes("user");
  const isManagementPage = pathname.includes("management");
  const isFeedPage = useMemo(() => pathname.includes("feed"), [pathname]);


  const siteIds = useMemo(() => site.map(site => site.id), [site])

  const { data: eventList } = useSWR(
    [swrEvents.pastUpcomingEvents, {
      sites: siteIds,
    }],
    fetcher,
    {
      fallbackData: { upcoming: [], past: [] },
      revalidateOnFocus: false,
      keepPreviousData: true,
    }
  )

  const handleSiteChange = value => {
    setSite(value);
    setEvent('')
  }

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      {isReviewPage && (
        <ReviewSelectStandard
          multiple
          isTitle
          value={status || filters.status}
          setValue={setStatus}
          items={constants.status.items}
          label={constants.status.label}
        />
      )}
      {(!isSitePage || (!isSitePage && isManagementPage)) && (
        <ReviewSelectStandard
          multiple
          label={constants.site.label}
          value={site}
          setValue={handleSiteChange}
          items={isLoadingSites ? [] : sites}
        />
      )}
      {!(isSitePage || isUserPage) && (
        <ReviewSelectStandard
          multiple
          label={constants.zone.label}
          value={zone}
          setValue={setZone}
          items={isLoadingZones ? [] : zones}
        />
      )}
      {isReviewPage && (
        <ReviewSelectStandard
          multiple
          label={constants.food.label}
          value={food}
          setValue={setFood}
          items={isLoadingFoods ? [] : foods}
        />
      )}
      {(isManagementPage || isUserPage) && (
        <ReviewSelectStandard
          multiple
          label={constants.group.label}
          value={group}
          setValue={setGroup}
          items={isLoadingGroup ? [] : parentGroups}
        />
      )}
      {isReviewPage && (
        <ReviewSelectStandard
          value={autoLabel || filters.autoLabel}
          setValue={setAutoLabel}
          items={constants.autoLabel.items}
          label={constants.autoLabel.label}
          emptyLabel="All"
          emptyValue="All"
        />
      )}
      {!isUserPage && !isManagementPage && (
        <>
          <DateInput
            isStartDate
            // fullWidth
            label="Start Date"
            date={startDate}
            setDate={setStartDate}
          />
          <DateInput
            // fullWidth
            label="End Date"
            date={endDate}
            setDate={setEndDate}
          />
        </>
      )}
      {isFeedPage && (
        <FormControl
          variant="outlined"
          fullWidth
        >
          <InputLabel id="event">Event</InputLabel>
          <Select
            id="event"
            name="event"
            value={event}
            onChange={e => setEvent(e.target.value)}
            displayEmpty
          >
            <MenuItem value=''>All</MenuItem>
            <ListSubheader>Upcoming</ListSubheader>
            {eventList.upcoming.map(event => (
              <MenuItem key={event.id} value={event.id}>{event.name}</MenuItem>
            ))}
            <ListSubheader>Past</ListSubheader>
            {eventList.past.map(event => (
              <MenuItem key={event.id} value={event.id}>{event.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {isUserPage && (
        <ReviewSelectStandard
          value={role || filters.role}
          setValue={setRole}
          items={constants.role.items}
          label={constants.role.label}
          emptyLabel="All"
          emptyValue="All"
        />
      )}
      <CustomButton onClick={submitHandler}>Apply filters</CustomButton>
    </Stack>
  );
}

export default HeaderFilter;
