import React, {useEffect, useRef, useState} from 'react';
import {styled, useTheme} from "@mui/material/styles";
import {Stack, Box, InputLabel, FormHelperText} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import { TimePicker as MuiTimePicker } from '@mui/x-date-pickers/TimePicker';
import {isFunc} from "../../utils/funcs";
import {decTime, incTime} from "./helpers";

const Wrapper = styled(Stack)(({ theme, error }) => ({
  border: `1px solid ${error ? theme.colors.red.primary : theme.colors.gray['200']}`,
  padding: "6px 14px",
  borderRadius: '8px',
  width: '100%',
}))

const TimeSelect = ({ view, format, value, onChange, onTimeUp, onTimeDown }) => {
  return(
    <Stack alignItems="center">
      <Box>
        <IconButton sx={{padding: '2px'}} onClick={onTimeUp}>
          <ExpandLess />
        </IconButton>
      </Box>
      <MuiTimePicker value={value} onChange={onChange} views={view} ampm={false} format={format} sx={{ maxWidth: '65px' }} />
      <Box>
        <IconButton sx={{padding: '2px'}} onClick={onTimeDown}>
          <ExpandMore />
        </IconButton>
      </Box>
    </Stack>
  )
}

const TimePicker = ({
  value,
  onChange,
  stepHours = 1,
  stepMinutes = 30,
  label,
  error,
  helperText,
}) => {
  const [time, setTime] = useState(value || Date.now())
  const init = useRef(false)
  const { colors } = useTheme()

  useEffect(() => {
    if (isFunc(onChange) && init.current) {
      onChange(time)
    }
    init.current = true
  }, [time]);

  useEffect(() => {
    if (value) {
      setTime(value)
    }
  }, [value]);

  const incHours = () => {
    setTime(incTime(time, stepHours, 'hours'))
  }
  const incMinutes = () => {
    setTime(incTime(time, stepMinutes, 'minutes'))
  }
  const decHours = () => {
    setTime(decTime(time, stepHours, 'hours'))
  }
  const decMinutes = () => {
    setTime(decTime(time, stepMinutes, 'minutes'))
  }

  return (
    <Stack sx={{ width: '100%'}}>
      <InputLabel>{label}</InputLabel>
      <Wrapper
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={2}
        error={error}
      >
        <TimeSelect
          value={time}
          onChange={setTime}
          view={['hours']} format="HH"
          onTimeUp={incHours}
          onTimeDown={decHours}
        />
        <span>:</span>
        <TimeSelect
          value={time}
          onChange={setTime}
          view={['minutes']}
          format="mm"
          onTimeUp={incMinutes}
          onTimeDown={decMinutes}
        />
      </Wrapper>
      {helperText && (
        <FormHelperText sx={{ color: colors.red.primary }}>{helperText}</FormHelperText>
      )}
    </Stack>
  );
};

export default TimePicker;
