import React from 'react';
import {ThemeProvider} from "@mui/material/styles";
import createTheme from "../../theme";
import {THEMES} from "../../constants/base";
import {SnackbarProvider} from "notistack";
import { Alert } from '../../ui'
import ModalProvider from "../../contexts/ModalContext";
import {Outlet} from "react-router-dom";

const RedesignProvider = () => {
  return (
    <ThemeProvider theme={createTheme(THEMES.REDESIGN)}>
      <SnackbarProvider
        preventDuplicate
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        TransitionProps={{ direction: "down" }}
        Components={{
          success: Alert,
          error: Alert,
        }}
      >
        <ModalProvider>
          <Outlet />
        </ModalProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default RedesignProvider;
