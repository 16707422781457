const gray = {
  primary: '#0D0D0D',
    900: '#222222',
    600: '#5A5C5B',
    secondary: '#878787',
    strokes: '#E9E9E9',
  400: '#F2F4F7',
  300: '#DAD6CA',
  200: '#D0D5DD',
  100: '#EBEAE5',
    50: '#F7F7F7',
    0: '#FFFFFF',
}

const yellow = {
  primary: '#FDC20E',
  dark: '#B08810',
  light: '#FFE596',
}

const green = {
  primary: '#1DBF73',
  dark: '#079455',
  light: '#CEFBDB',
}

const red = {
  primary: '#EA4335',
  dark: '#E78F8F',
  light: '#FFE8E8',
}

const other = {
  orange: '#FF9A0A',
  tertiary: '#FFF1C6',
  label: '#667085',
  chipInfo: '#C28883',
  slider: '#34A853',
}

export const colorPalette = {
  gray,
  yellow,
  green,
  red,
  other,
};
