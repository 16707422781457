import React from 'react';
import {Stack} from "@mui/material";

const Form = ({ onSubmit, children }) => {
  return (
    <form onSubmit={onSubmit}>
      <Stack spacing={4}>
        {children}
      </Stack>
    </form>
  );
};

export default Form;