import React, {useState} from 'react';
import {Box, Tooltip as BaseTooltip} from "@mui/material";

const Tooltip = ({ title, children, ...props }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <BaseTooltip
      {...props}
      leaveTouchDelay={5000}
      title={title}
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
    >
      <Box display="inline-block" onClick={() => setIsOpen(true)}>
        { children }
      </Box>
    </BaseTooltip>
  );
};

export default Tooltip;