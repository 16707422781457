import React from 'react';
import {Paper} from "@mui/material";
import styled from "@emotion/styled";

const StyledPaper = styled(Paper)(({ theme}) => ({
  backgroundColor: theme.palette.background.paper,
  padding: '24px',
  borderRadius: '24px',
}))

const Area = ({ children, style }) => {
  return (
    <StyledPaper elevation={0} style={style}>
      {children}
    </StyledPaper>
  );
};

export default Area;
