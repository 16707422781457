import ReactDom from "react-dom";
import FilterReport from "./FilterReport";
import html2canvas from "html2canvas";
import {downloadFile} from "../../utils/downloadFile";
import React from "react";
import JsPDF from "jspdf";
import {CHART_TYPE, DATE_GRAN, MAX_AVAILABLE_DATE_BY_GRAN, METRICS} from "../../constants/explore";
import dayjs from "dayjs";
import {formatDataQuery} from "../../utils/date/formatDataQuery";
import {normalizeFilters} from "./storeMappers";
import {pipe} from "../../utils/pipe";
import {localDateFormat} from "../../utils/date/localDate";
import {quaterOfTheYear} from "../overview/utils/formaters";
import numberFormater from "../../utils/formaters/numberFormater";
import {getLang} from "../../utils/lang";
import utc from "dayjs/plugin/utc";
import {extractTimeZone} from "../../utils/date/localTime";
dayjs.extend(utc)
export const CHART_AXIS = 'chartAxis'

const sleep = ms => new Promise(resolve => setTimeout(() => resolve(), ms))
export const saveAsImage = async (filters) => {
  const input = document.getElementById('explore-dashboard');
  const cloneDashboard = input.cloneNode(true)
  const preview = document.createElement('div')
  preview.id = 'preview'
  preview.style.position = 'absolute'
  preview.style.left = '-2000px'
  preview.style.width = '100%'
  preview.style.zIndex = '0'
  preview.appendChild(cloneDashboard)
  const temp = document.createElement('div')
  preview.appendChild(temp)
  document.body.appendChild(preview)
  ReactDom.render(<FilterReport filters={filters}/>, temp)

  // need for translation
  await sleep(1000)
  return html2canvas(preview)
    .then(canvas => {
      const imgData = canvas.toDataURL('image/jpeg');
      downloadFile(imgData, 'report')
      preview.remove()
    })
}


export const saveToPDF = async (filters) => {
  const input = document.getElementById('explore-dashboard');
  let totalHeight = 0;
  let yPos = 20

  return html2canvas(input, { scale: 2 }).then((canvas) => {
    const pdf = new JsPDF('p', 'pt', 'a4', true);
    const imgWidth = 480
    const segmentHeight = 2210
    const totalPages = Math.ceil(canvas.height / segmentHeight);


    for (let i = 0; i < totalPages; i++) {
      if (i > 0) {
        pdf.addPage();
        totalHeight += segmentHeight;
        yPos = 20
      }

      const segmentCanvas = document.createElement('canvas');
      segmentCanvas.width = canvas.width;
      segmentCanvas.height = Math.min(segmentHeight, canvas.height - totalHeight);
      const ctx = segmentCanvas.getContext('2d');
      ctx.drawImage(canvas, 0, totalHeight, canvas.width, segmentCanvas.height, 0, 0, canvas.width, segmentCanvas.height);

      const imgData = segmentCanvas.toDataURL('image/png');
      pdf.addImage(imgData, 'PNG', 20, 20, imgWidth, (imgWidth * segmentCanvas.height) / segmentCanvas.width);
      if (i === totalPages - 1 ) {
        const contentHeight = (imgWidth * segmentCanvas.height) / segmentCanvas.width
        if (segmentHeight - contentHeight < 300) {
          pdf.addPage()
        }
        const temp = document.createElement('div')
        temp.style.position = 'absolute'
        temp.style.top = '-1000px'
        temp.style.width = '100%'
        temp.id = 'temp'
        ReactDom.render(<FilterReport filters={filters} />, temp)
        document.body.append(temp)
        return html2canvas(temp, { scale: 2 })
          .then(canva => {
            const img = canva.toDataURL('image/png');
            const filterHeight = (canva.height * imgWidth) / canva.width;
            pdf.addImage(img, 'PNG', 20, contentHeight + 50,  imgWidth, filterHeight);
            pdf.save('report.pdf')
            temp.remove()
          })
      }
    }

    pdf.save('report.pdf');
  });
};


export const getAvailableDateRange = gran => {
  if (MAX_AVAILABLE_DATE_BY_GRAN[gran]) {
    return [new Date(dayjs().subtract(MAX_AVAILABLE_DATE_BY_GRAN[gran], 'day')), new Date(dayjs())]
  }
}

export const mepMetricParams = {
  [METRICS.totalWaste]: 'kg',
  [METRICS.wasteLogs]: 'interactions',
  [METRICS.wasteEmission]: 'co2',
  [METRICS.AVGWaste]: 'waste_per_site',
  [METRICS.coastWaste]: '€',
  [METRICS.waterFootprint]: 'water_footprint',
  [METRICS.mealWaste]: 'equivalent_meals',
  [METRICS.occupancy]: METRICS.occupancy,
}

export const filtersToExploreParams = filters => {
  const {
    metric,
    labels,
    dateRange,
    sites,
    food,
    dateGranularity,
    rollingAverage,
    zones,
    event,
  } = normalizeFilters(filters)
  return {
    metric: mepMetricParams[metric],
    label: labels,
    site: sites.map(site => site.id),
    food: food.map(item => item.id),
    startDate: formatDataQuery(dateRange[0]),
    endDate: formatDataQuery(dateRange[1]),
    view: dateGranularity,
    ...(rollingAverage ? { rolling_average: rollingAverage} : {}),
    serial: zones.map(zone => zone.serial),
    ...(event ? { event } : {})
  }
}

const tickTemplate = {
  [DATE_GRAN.hourly]: 'DD-MM-YYYY, HH:mm',
  [DATE_GRAN.daily]: 'DD-MM-YYYY',
  [DATE_GRAN.weekly]: 'DD/MM/YYYY',
  [DATE_GRAN.monthly]: 'MMM YYYY',
  [DATE_GRAN.quarterly]: '',
  [DATE_GRAN.annually]: 'YYYY',
  [CHART_AXIS]: 'DD/MM/YY',
}

const formatMapper = {
  [DATE_GRAN.weekly]: date => dayjs(date).week(),
  [DATE_GRAN.quarterly]: quaterOfTheYear,
}

export const formatTicker = (duration) => date => {
  try {
    const locale = getLang()
    dayjs.locale(locale)
    const template = tickTemplate[duration]
    if ([DATE_GRAN.quarterly].includes(duration)) {
      return `${dayjs(date).utc().format('YYYY')}, ${pipe(date, formatMapper[duration])}`
    }
    const { date: dateString } = extractTimeZone(date)
    return dayjs(dateString).format(template)
  }catch (error) {
    return localDateFormat(date)
  }
}

const getHourlyTick = (data) => {
  const ticks = {}
  data.forEach(item => {
    const key = dayjs(item.x).format('MMM/DD/YY')
    if(!ticks[key]) {
      ticks[key] = item.x
    }
  })
  return Object.values(ticks)
}

const TICKS_BY_DURATION = {
  [DATE_GRAN.hourly]: getHourlyTick
}

export const getTickConfig = (data, duration) => {
  if(TICKS_BY_DURATION[duration]) {
    return {
      ticks: pipe(data, TICKS_BY_DURATION[duration]),
      tickFormatter: formatTicker(CHART_AXIS),
      angle: 45,
      textAnchor: 'start'
    }
  }
  if(DATE_GRAN.daily === duration) {
    return {
      tickFormatter: formatTicker(CHART_AXIS),
    }
  }
  return {}
}

export const getTotal = (chartData = [], labels = {}) => {
  const activeLabels = Object.values(labels).filter(label => label?.isActive).map(item => item.key)
  return chartData.reduce((acc, item) => {
    activeLabels.forEach(label => {
      acc += item.meta[label].originalValue
    })
    return acc
  }, 0)
}

export const formatChartValues = decimal => (value, unit) => {
  try {
    return numberFormater({ value, fixed: decimal, after: unit})
  }catch (e) {
    return value
  }
}

const MERKER = {
  1000: 'K',
  1_000_000: 'M',
}

const formatNumberToDisplay = target => number => {
  if (+number >= +target) {
    return `${(parseFloat(number) / target ).toFixed(1).toLocaleString("en-GB")}${MERKER[target]}`
  }
  return number
}

export const formatYaxis = value => {
  if (value >= 1_000_000) {
    return pipe(value, formatNumberToDisplay(1_000_000))
  }
  return pipe(value, formatNumberToDisplay(1_000))
}

export const validateDateRange = (dates) => {
  const [startDate, endDate] = dates.map(dayjs); // Create dayjs objects // Check if both dates are valid const isStartDateValid = startDate.isValid();
  const isEndDateValid = endDate.isValid();
  const isStartDateValid = startDate.isValid()

  if (!isStartDateValid || !isEndDateValid) {
    return { isValid: false, message: "Both dates must be valid dates." };
  }

  if(startDate.isAfter(endDate)) {
  return { isValid: false, message: "Start date can be greater than the end date." };
  }

  if (endDate.isAfter(Date.now())) {
    return { isValid: false, message: "End date can't be greater than today's date." };
  }

  if( startDate.isBefore(new Date('01.01.1900'))) {
    return { isValid: false, message: "Invalid date" };
  }
  return { isValid: true, message: '' }
}
