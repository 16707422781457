import React from 'react';
import {InputAdornment, TextField} from "@mui/material";
import Icon from "../Icon";
import {useTheme} from "@mui/material/styles";

const Adornment = () => {
  const { colors } = useTheme()
  return (
    <InputAdornment
      sx={{ paddingRight: '10px'}}
      position="end"
    >
      <Icon color={colors.red.primary} icon="info" />
    </InputAdornment>
  )
}

const adornment = {
  endAdornment: <Adornment />
}

const InputField = (props) => {
  return (
    <TextField
      {...props}
      InputProps={{
        ...props?.InputProps,
      ...(props.error ? adornment : {}),
      }}
    />
  );
};

export default InputField;