import React from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select as MuiSelect
} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import {useTheme} from "@mui/material/styles";
import OutlinedInput from '@mui/material/OutlinedInput';

const getLabel = options => value => options.find((option) => value === option.value)?.label;


const renderValue = (options, placeholder) => selected => {
  return (
    Array.isArray(selected)
      ? selected.map(getLabel(options)).join(', ') || placeholder
      : selected || placeholder
  )
}

const Select = ({
  label,
  value,
  onChange,
  id,
  name,
  options = [],
  disabled,
  fullWidth,
  placeholder,
  error,
  helperText,
  multi,
 }) => {
  const { colors } = useTheme()
  const checkIsSelected = (optionValue) => multi
    ? value.includes(optionValue)
    : value === optionValue;

  return (
    <FormControl variant="outlined" fullWidth={fullWidth} disabled={disabled} error={error}>
      <InputLabel id="metric">{label}</InputLabel>
      <MuiSelect
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        displayEmpty
        defaultValue={multi ? [] : ''}
        multiple={multi}
        input={<OutlinedInput />}
        renderValue={multi && renderValue(options, placeholder)}
      >
        <MenuItem value={multi ? [] : ''} disabled>
          <ListItemText sx={{ color: colors.gray['secondary'] }}>
            {placeholder}
          </ListItemText>
        </MenuItem>
        {options.map(({ value: optionValue, label }) => (
          <MenuItem key={optionValue} value={optionValue}>
            <ListItemText>{label}</ListItemText>
            {checkIsSelected(optionValue) && (
              <CheckIcon />
            )}
          </MenuItem>
        ))}
      </MuiSelect>
      {helperText && (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

export default Select;
