import React from 'react';
import {Stack} from "@mui/material";
import { Header as LayoutHeader } from '../../../layouts/Redesign'
import Button from "@mui/material/Button";
import TabLinks from "../../../ui/TabLinks";
import {mainRoutes} from "../../../constants/urls";
import {useParams} from "react-router-dom";
import {getPathFromParams} from "../../../routes";

const links = [
  {
    title: 'Events',
    path: `/${mainRoutes.venus.events}`
  },
  {
    title: 'Info',
    path: `/${mainRoutes.venus.info}`
  },
]

const getLinks = siteId => links.map(link => ({
  ...link,
  path: getPathFromParams(link.path, { siteId }),
}))

const Header = ({ title, actions = [] }) => {
  const { siteId } = useParams();
  return (
    <Stack spacing={5}>
      <LayoutHeader>
        <LayoutHeader.Title>{title}</LayoutHeader.Title>
        <LayoutHeader.Actions>
          {actions.map((action) => (
            <Button key={action.title} {...action.props}>{action.title}</Button>
          ))}
        </LayoutHeader.Actions>
      </LayoutHeader>
      {/*<TabLinks links={getLinks(siteId)} />*/}
    </Stack>
  );
};

export default Header;
