import React from 'react';
import {styled} from "@mui/material/styles";
import {Box, Stack} from "@mui/material";

const Step = styled(Box)(({ theme, passed }) => ({
  height: '6px',
  backgroundColor: passed ? theme.colors.yellow.primary : theme.colors.yellow.light,
  flex: 1,
  borderRadius: '200px',
}))

const Stepper = ({ steps, currentStep }) => {
  return (
    <Stack spacing={2} direction="row" flexWrap="no-wrap" sx={{ width: '100%' }}>
      {Array(steps).fill(1).map((step, i) => (
        <Step key={i} passed={currentStep >= i} />
      ))}
    </Stack>
  );
};

export default Stepper;
