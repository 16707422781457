import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { baseAxios } from "../utils/axios";

export const fetchSites = createAsyncThunk(
  "sites/fetchSites",
  async (params) => {
    const { fields, viewAs, filters = {} } = params || {};
    const response = await baseAxios.get("api/sites/", {
      params: {
        ...(fields ? { fields } : {}),
        ...(filters.aiLabel ? { ml_label: filters.aiLabel } : {})
      },
      env: viewAs ? {} : { viewAs },
    });
    return response.data;
  }
);

const initialState = {
  isLoading: false,
  hasError: false,
  sites: [],
};

export const sitesSlice = createSlice({
  name: "sites",
  initialState,
  reducers: {
    addSite: (state, { payload }) => {
      state.sites.push(payload);
    },
    updateSiteDeviceSerial: (state, { payload }) => {
      state.sites = state.sites.map((site) => {
        if (site.id === payload.site_id) {
          return {
            ...site,
            device_name: payload.name,
            device_serial: payload.serial,
          };
        }
        return site;
      });
    },
    updateSite: (state, { payload }) => {
      state.sites = state.sites.map((site) => {
        if (site.id === payload.id) {
          return { ...site, ...payload };
        }
        return site;
      });
    },
    clearSites: (state) => {
      state.sites.length = 0;
    },
    resetSites: () => initialState,
  },
  extraReducers: {
    [fetchSites.pending]: (state) => {
      state.isLoading = true;
      state.hasError = false;
    },
    [fetchSites.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.hasError = false;
      state.sites = [...payload];
    },
    [fetchSites.rejected]: (state) => {
      state.isLoading = false;
      state.hasError = true;
      state.sites = [];
    },
  },
});

export const {
  updateSiteDeviceSerial,
  updateSite,
  clearSites,
  addSite,
  resetSites
} =
  sitesSlice.actions;

export const selectSites = (state) => state.sites;
export const selectSelectedSite = id => state => {
  if (id) {
    const site = state.sites.sites.find(s => +s.id === +id);
    if (site) return site
  }
  return state.explore.filters.sites[0] || state.sites.sites[0];
}

export default sitesSlice.reducer;
