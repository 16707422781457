const typography = {
  fontFamily: [
    "Poppins",
    "Inter",
    "Sarabun",
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  fontSize: 13,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  h4: {
    fontSize: "1.125rem",
    fontWeight: 500,
    lineHeight: 1.25,
  },
  h5: {
    fontSize: "1.0625rem",
    fontWeight: 500,
    lineHeight: 1.25,
  },
  h6: {
    fontSize: "1rem",
    fontWeight: 500,
    lineHeight: 1.25,
  },
  large: {
    fontSize: "56px",
    lineHeight: "84px",
    letterSpacing: "0px",
    fontWeight: 500,
  },
  large2: {
    fontWeight: 700,
    fontSize: '34px',
    lineHeight: '42px'
  },
  title: {
    fontWeight: 700,
    fontSize: '26px',
    lineHeight: '30px'
  },
  medium: {
    fontSize: "24px",
    lineHeight: "32px",
    letterSpacing: "0px",
    fontWeight: 700,
  },
  h1: {
    fontSize: "20px",
    lineHeight: "30px",
    letterSpacing: "0.1px",
    fontWeight: 500,
  },
  h2: {
    fontSize: "18px",
    lineHeight: "28px",
    letterSpacing: "0.1px",
    fontWeight: "500",
  },
  h3: {
    fontSize: "15px",
    lineHeight: "23px",
    letterSpacing: "0.1px",
    fontWeight: "500",
  },
  subtitle: {
    fontSize: "16px",
    lineHeight: "42px",
    letterSpacing: "0px",
    fontWeight: "700",
  },
  subtitle1: {
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0px",
    fontWeight: "500",
  },
  subtitle2: {
    fontSize: "13px",
    lineHeight: "20px",
    letterSpacing: "0.1px",
    fontWeight: "500",
  },
  button: {
    fontSize: "13px",
    lineHeight: "20px",
    letterSpacing: "0.1px",
    fontWeight: 600,
    textTransform: "none",
  },
  body1: {
    fontSize: "13px",
    lineHeight: "20px",
    letterSpacing: "0.1px",
    fontWeight: 400,
  },
  body2: {
    fontSize: "12px",
    lineHeight: "20px",
    letterSpacing: "0.1px",
    fontWeight: 400,
  },
  small1: {
    fontSize: "12px",
    lineHeight: "18px",
    letterSpacing: "0.2px",
    fontWeight: 500,
  },
  small2: {
    fontSize: "11px",
    lineHeight: "16px",
    letterSpacing: "0.2px",
    fontWeight: 500,
  },
  small3: {
    fontSize: "10px",
    lineHeight: "16px",
    letterSpacing: "0.2px",
    fontWeight: 400,
  },
  italic1: {
    fontSize: "12px",
    lineHeight: "18px",
    letterSpacing: "0.1px",
    fontWeight: 400,
    fontStyle: "italic",
  },
  italic2: {
    fontSize: "10px",
    lineHeight: "14px",
    letterSpacing: "0.1px",
    fontWeight: 400,
    fontStyle: "italic",
  },
};

export default typography;

export const typographyRedesign = {
  fontFamily: 'Inter, Lufga, ans-serif',
  fontSize: 16,
  button: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontWeight: 600,
    textTransform: 'none',
  },
  displayLg: {
    fontSize: '3rem',      // 48px
    lineHeight: '3.75rem', // 60px
    fontWeight: '500',     // Medium
  },
  displayMd: {
    fontSize: '2.25rem',   // 36px
    lineHeight: '2.75rem', // 44px
    fontWeight: '600',     // Semibold
  },
  displaySm: {
    fontSize: '1.875rem',  // 30px
    lineHeight: '2.375rem', // 38px
    fontWeight: '600',      // Semibold
    fontFamily: 'Lufga',
  },
  displayXs: {
    fontSize: '1.5rem',    // 26px
    lineHeight: '2rem',     // 36px
    fontWeight: '600',      // Semibold
  },
  textXl: {
    fontSize: '1.25rem',    // 20px
    lineHeight: '1.875rem',  // 30px
    fontWeight: '500',
    fontFamily: 'Lufga',
  },
  textLg: {
    fontSize: '1.125rem',    // 18px
    lineHeight: '1.75rem',    // 28px
    fontWeight: '500',        // Medium
  },
  textMd: {
    fontSize: '1rem',         // 16px
    lineHeight: '1.5rem',     // 24px
    fontWeight: '400',        // Regular
  },
  textSm: {
    fontSize: '0.875rem',     // 14px
    lineHeight: '1.25rem',     // 20px
    fontWeight: '400',        // Regular
  },
  textXs: {
    fontSize: '0.75rem',      // 12px
    lineHeight: '1.125rem',    // 18px
    fontWeight: '400',
  },
};
