import dayjs from "dayjs";

export const incTime = (time, count, unit) => (
  time
    ? dayjs(time).add(count, unit).toDate()
    : dayjs().startOf('day').toDate()
)
export const decTime = (time, count, unit)  => (
  time
    ? dayjs(time).subtract(count, unit).toDate()
    : dayjs().startOf('day').toDate()
)
