import React, {useCallback, useEffect, useState} from 'react';
import Modal from "../../../../../ui/Modal";
import {Button, Stack, TextField, useMediaQuery} from "@mui/material";
import Form from "../../../../../layouts/Form";
import {DatePicker, Select, TimePicker} from "../../../../../ui";
import {ADD_EDIT_EVENT_MODAL, CONFIRM_UPDATE_EVENT, EVENTS_OPTIONS} from "../../constants";
import * as Yup from "yup";
import {onlyIntegers, date, required, requiredText, time} from "../../../../../utils/validation/base";
import dayjs from "dayjs";
import {Formik, useFormik} from "formik";
import useSWRMutation from "swr/mutation";
import {getPathFromParams} from "../../../../../routes";
import {events} from "../../../../../utils/swrs/urls";
import {fetchMutation} from "../../../../../utils/axios";
import {camelToSnake} from "../../../../../utils/formaters/snakeCase";
import {useParams} from "react-router-dom";
import {useSnackbar} from "notistack";
import {getErrorMessage} from "source-map-explorer/lib/app-error";
import {mutate} from "swr";
import {getKey} from "../../../../../utils/swrs/helpers";
import {useModal} from "../../../../../hooks/useModal";
import Confirm from "../../../../../modals/Confirm";
import {getDateInTimezone, getTimezoneUtc, testDate} from "../../../../../utils/date/localTime";
import {useSelector} from "react-redux";
import {selectSelectedSite} from "../../../../../redux/sites";
import {ONLY_NUMBERS_REGEX} from "../../../../../constants/base";
import {getCovers} from "../../../../../components/sites/axios/getCovers";

const validation = Yup.object().shape({
  name: requiredText.max(100, '100 characters max'),
  description: requiredText.max(2500, '2500 characters max'),
  startDate: required(date),
  endTime: required(date)
  .test("is-greater", "The end time should be after the start time for single day", function (value) {
    const { startDate, endDate, startTime } = this.parent;
    const start = dayjs(startDate);
    const end = dayjs(endDate);

    return end.isSame(start, 'day')
      ? dayjs(value).isAfter(dayjs(startTime), 'minute')
      : true
  }),
  startTime: required(time),
  occupancyType: requiredText,
  estimateOccupancy: onlyIntegers.required('Required field').moreThan(0, 'Required field'),
  endDate: required(date)
    .test("is-greater", "The end date should be after the start date", function (value) {
      const { startDate, endTime, startTime } = this.parent;
      const start = dayjs(startDate);
      const end = dayjs(value);
      const isEndTimeGr = dayjs(startTime).isBefore(dayjs(endTime));

      if(end.isAfter(startDate, 'day')) return true;
      if(end.isBefore(start, 'day')) return false;

      return isEndTimeGr
    }),
});

const handleNumbersChange = handler => event => {
  event.preventDefault()
  const value = event.target.value;
  const name = event.target.name;
  if (!value || ONLY_NUMBERS_REGEX.test(value)) {
    handler(name, +value)
  }
}

const initialData = timeFormat => ({
  name: '',
  description: '',
  startDate: timeFormat(new Date()),
  endDate: timeFormat(new Date()),
  estimateOccupancy: '',
  occupancyType: '',
  startTime: new Date(timeFormat(new Date).setHours(9, 0, 0)),
  endTime: new Date(timeFormat(dayjs(new Date())).setHours(17, 0, 0)),
})

const mapEventToState = event => ({
  ...event,
  startDate: new Date(event.startDate),
  endDate: new Date(event.endDate),
  startTime: new Date(event.startDate),
  endTime: new Date(event.endDate),
})

const handleDateChange = (key, cb) => value => cb(key, value, false)

const concatDate = (date, time) => {
  const d = dayjs(date)
  const h = dayjs(time).hour()
  const m = dayjs(time).minute()
  return d.set('hours', h).set('minutes', m).toDate()
}

const AddEvent = ({ bind, close, modalProps, ...props}) => {
  const { title, data } = modalProps
  const isSmallMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const direction = isSmallMobile ? 'column' : 'row';
  const { siteId } = useParams()
  const site = useSelector(selectSelectedSite(siteId))
  const getUtcInSiteLocation = getTimezoneUtc(site.timezone)
  const getDateInSiteLocation = getDateInTimezone(site.timezone)
  const { enqueueSnackbar } = useSnackbar()
  const initValue = data ? mapEventToState(data) : initialData(getDateInSiteLocation)
  const fetcher = data ? fetchMutation.patch : fetchMutation.post
  const requestUrl = data
    ? getPathFromParams(events.updateEvents, { id: data.id, site: siteId })
    : events.createEvents
  const { open: showConfirmModal, close: closeConfirm } = useModal(Confirm, { autoClose: false })
  const { trigger: createEvent } = useSWRMutation(
    requestUrl,
    fetcher,
  )
  const [shouldShowChooseModal, setShouldShowChooseModal] = useState(false)

  const successMessage = data
    ? ADD_EDIT_EVENT_MODAL.successUpdated
    : ADD_EDIT_EVENT_MODAL.successCreated

  const confirm = useCallback(() => {
    if (shouldShowChooseModal) {
      return showConfirmModal( {
        title: CONFIRM_UPDATE_EVENT.title,
        rejectText: CONFIRM_UPDATE_EVENT.add,
        confirmText: CONFIRM_UPDATE_EVENT.replace,
        description: CONFIRM_UPDATE_EVENT.description,
        type: 'variant',
        descriptionTip: CONFIRM_UPDATE_EVENT.descriptionTip,
      })
    }
    return Promise.resolve(true)
  },[shouldShowChooseModal])

  const submit = ({ startTime, endTime, ...value }) => {
    confirm()
      .then(replace => createEvent(camelToSnake({
        ...value,
        site: +siteId,
        replace: !!replace,
        start_date: getUtcInSiteLocation(concatDate(value.startDate, startTime)),
        end_date: getUtcInSiteLocation(concatDate(value.endDate, endTime)),
      }))
        .then(res => {
          enqueueSnackbar(successMessage, { variant: 'success' })
          mutate(getKey(getPathFromParams(events.baseUrl, { site: siteId })))
          bind(res)
        })
        .catch(error => enqueueSnackbar(getErrorMessage(error), { variant: 'error' })))
        .finally(closeConfirm)
  }

  const {
    values,
    handleSubmit,
    handleChange,
    errors,
    setFieldValue,
    touched,
  } = useFormik({
    initialValues: initValue,
    validationSchema: validation,
    onSubmit: submit,
    validateOnBlur: true,
  })

  useEffect(() => {
    getCovers({ siteId, startDate: values.startDate, endDate: values.endDate })
      .then(data => setShouldShowChooseModal(!!data.length))
  }, [values.startDate, values.endDate]);

  return (
    <Modal {...props} onClose={close}>
      <Modal.Title>{title}</Modal.Title>
        <Modal.Content>
          <Form>
            <TextField
              label="Name"
              name="name"
              placeholder="Type event name"
              value={values.name}
              onChange={handleChange}
              error={!!errors.name && touched.name}
              helperText={touched.name && errors.name}
            />
            <Select
              label="Event Type"
              name="occupancyType"
              placeholder="Select event type"
              options={EVENTS_OPTIONS}
              onChange={handleChange}
              value={values.occupancyType}
              error={touched.occupancyType && !!errors.occupancyType}
              helperText={touched.occupancyType && errors.occupancyType}
            />
            <TextField
              label="Description"
              placeholder="Some event description here..."
              multiline
              rows={3}
              name="description"
              onChange={handleChange}
              value={values.description}
              error={!!errors.description && touched.description}
              helperText={touched.description && errors.description}
            />
            <Stack direction={direction} spacing={2}>
              <DatePicker
                label="Start Date"
                name="startDate"
                onChange={handleDateChange('startDate', setFieldValue)}
                value={values.startDate}
                error={!!errors.startDate && touched.startDate}
                helperText={touched.startDate && errors.startDate}
              />
              <DatePicker
                label="End Date"
                name="endDate"
                value={values.endDate}
                onChange={handleDateChange('endDate', setFieldValue)}
                error={!!errors.endDate && touched.endDate}
                helperText={touched.endDate && errors.endDate}
              />
            </Stack>
            <Stack direction={direction} spacing={2}>
              <TimePicker
                label="Start Time"
                name="startTime"
                onChange={handleDateChange('startTime', setFieldValue)}
                value={values.startTime}
                error={!!errors.startTime && touched.startTime}
                helperText={touched.startTime && errors.startTime}
              />
              <TimePicker
                label="End Time"
                name='endTime'
                onChange={handleDateChange('endTime', setFieldValue)}
                value={values.endTime}
                error={!!errors.endTime && touched.endTime}
                helperText={touched.endTime && errors.endTime}
              />
            </Stack>
            <TextField
              label="Estimate Occupancy per day"
              placeholder="Type event estimate occupancy"
              value={values.estimateOccupancy}
              name="estimateOccupancy"
              onChange={handleNumbersChange(setFieldValue)}
              error={!!errors.estimateOccupancy && touched.estimateOccupancy}
              helperText={touched.estimateOccupancy && errors.estimateOccupancy}
            />
          </Form>
        </Modal.Content>
        <Modal.Footer>
          <Modal.Actions>
            <Modal.Action>
              <Button fullWidth color="secondary" onClick={close}>Cancel</Button>
            </Modal.Action>
            <Modal.Action>
              <Button onClick={handleSubmit} fullWidth>{data ? 'Update' : 'Add'}</Button>
            </Modal.Action>
          </Modal.Actions>
        </Modal.Footer>
    </Modal>
  );
};

export default AddEvent;