import React from "react";
import {Navigate, useLocation} from "react-router-dom";

// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size
// Layouts
import AuthLayout from "./layouts/Auth";
import BaseTemplate from "./layouts/BaseTemplate";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth components
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ResetPassword from "./pages/auth/ResetPassword";
import ResetPasswordDone from "./pages/auth/ResetPasswordDone";
import ResetPasswordComplete from "./pages/auth/ResetPasswordComplete";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";

// Head Office Menu
import Overview from "./pages/main/Overview";
import Sites from "./pages/main/Sites";
import Zones from "./pages/main/Zones";
import Foods from "./pages/main/Foods";
import CategoryDrilldown from "./components/categoryDrilldown/CategoryDrilldown";

// Admin Menu
import Review from "./pages/admin/Review";
import Labelling from "./pages/admin/Labelling";
import Verification from "./pages/admin/Verification";
import Users from "./pages/admin/Users";
import Profile from "./pages/pages/Profile";
import DeviceManagement from "./pages/admin/DeviceManagement";
import SiteManagement from "./pages/admin/SiteManagement";
import GroupManagement from "./pages/admin/GroupManagement";
import Feed from "./pages/main/Feed";
import BaseSignIn from "./pages/auth/BaseSignIn";
import CheckEmail from "./pages/auth/CheckEmail";
import Onboarding from "./pages/main/Onboarding";
import OnboardingGuard from "./components/guards/OnboardingGuard";
import Explore from "./pages/main/Explore";
import { mainRoutes } from "./constants/urls";
import Widgets from "./pages/main/Widgets";
import DataConnector from "./pages/main/DataConnector";
import AdminGuard from "./components/guards/AdminGuard";
import Blank from "./pages/pages/Blank";
import ModalProvider from "./contexts/ModalContext";
import createTheme from "./theme";
import {THEMES} from "./constants/base";
import {ThemeProvider} from "@mui/material/styles";
import RedesignProvider from "./layouts/Redesign/Provider";
import Events from "./pages/main/venus/Events";

const RedirectWithSearch = ({ to }) => {
  const location = useLocation();
  return <Navigate to={`${to}${location.search}`} />;
};

const routes = [
  {
    path: "",
    element: (
      <AuthGuard>
        <BaseTemplate />
      </AuthGuard>
    ),
    children: [
      {
        path: "overview",
        element: <Overview />,
      },
      {
        path: `overview/${mainRoutes.widgets}`,
        element: <Widgets />,
      },
      {
        path: "feed",
        element: <Feed />,
      },
      {
        path: "insights",
        children: [
          {
            path: "",
            element: (
              <RedirectWithSearch
                to="foods"
              />
            ),
          },
          {
            path: "foods",
            element: <Foods />
          },
          {
            path: "foods/:foodId",
            element: <CategoryDrilldown />
          },
          {
            path: "/insights/monitors",
            element: <Zones />,
          },
          {
            path: "/insights/sites",
            element: <Sites />,
          },
        ],
      },
      {
        path: mainRoutes.explore,
        element: <Explore />
      },
      {
        path: "settings",
        children: [
          {
            path: "",
            element: (
              <Navigate to="/settings/profile" state={window.location.search} />
            ),
          },
          {
            path: "*",
            element: (
              <Navigate to="/settings/profile" state={window.location.search} />
            ),
          },
          {
            path: "/settings/profile",
            element: <Profile state={window.location.search} />,
          },
          {
            path: "/settings/data-connector",
            element: <DataConnector />,
          },
        ],
      },
      {
        path: mainRoutes.venus.base,
        element: <RedesignProvider />,
        children: [
          {
            path: '',
            element: <Navigate to={`events`} />
          },
          {
            path: "events",
            element: <Events />
          },
        ]
      },
    ],
  },
  {
    path: "",
    element: (
      <AuthGuard>
        <AdminGuard>
          <BaseTemplate />
        </AdminGuard>
      </AuthGuard>
    ),
    children: [
      {
        path: "review",
        element: <Review state={window.location.search} />,
      },
      {
        path: "labelling",
        element: <Labelling state={window.location.search} />,
      },
      {
        path: "Verification",
        element: <Verification state={window.location.search} />,
      },
      {
        path: "device-management",
        element: <DeviceManagement state={window.location.search} />,
      },
      {
        path: "site-management",
        element: <SiteManagement state={window.location.search} />,
      },
      {
        path: "group-management",
        element: <GroupManagement state={window.location.search} />,
      },
      {
        path: "users",
        element: <Users state={window.location.search} />,
      },
      {
        path: "test-ui",
        element:  <ThemeProvider theme={createTheme(THEMES.REDESIGN)}><ModalProvider><Blank /></ModalProvider></ThemeProvider>,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <BaseSignIn />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "reset-password/done",
        element: <ResetPasswordDone />,
      },
      {
        path: "reset-password/:uid64/:token",
        element: <ResetPasswordComplete />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
      {
        path: 'sign-in-with-password',
        element: <SignIn />,
      },
      {
        path: 'check-email/:email',
        element: <CheckEmail />,
      },
    ],
  },
  {
    path: 'onboarding',
    element: (
      <OnboardingGuard>
        <AuthLayout>
          <Onboarding />
        </AuthLayout>
      </OnboardingGuard>
      )
  },
  { path: "*", element: <Page404 /> },
];

export default routes;
export const getPathFromParams = (route, params) => route.replace(
  /:([a-zA-Z]*)/g,
  (_, key) => params[key] ? String(params[key]) : `:${key}`,
)
