const eventsBaseUrl = 'api/venues-events/:site/';
const calendarEvents = `${eventsBaseUrl}calendar/`;
const updateEvents = `${eventsBaseUrl}:id/`;
const createEvents = `api/venues-events/`;
const pastUpcomingEvents = '/api/venues-events/upcoming_and_past_events/'

export const events = {
  baseUrl: eventsBaseUrl,
  calendarEvents,
  updateEvents,
  createEvents,
  pastUpcomingEvents,
}