import React from 'react';
import {Stack} from "@mui/material";
import Spinner from "../Spinner";

const Loader = ({ children, loading, height }) => {
  if (loading) {
    return (
      <Stack
        justifyContent="center" alignItems="center"
        sx={{ width: '100%', height: height || '100%' }}
      >
        <Spinner />
      </Stack>
    )
  }
  return children;
};

export default Loader;