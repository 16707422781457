import React from 'react';
import {Stack, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {Icon} from "../../../../../ui";
import {useModal} from "../../../../../hooks/useModal";
import Filter from "../../modals/Filter";

const Header = ({ expanded, toggleExpand, onFiltersChange, filters }) => {
  const { open } = useModal(Filter, { side: true })
  const handleOpenFilters = () => open({
    filters,
  })
    .then(onFiltersChange)

  return (
    <Stack direction="row" justifyContent="space-between">
      <Typography variant="textXl">All Events</Typography>
      <Stack direction="row" spacing={2}>
        <Button onClick={handleOpenFilters} startIcon={<Icon size="small" icon="filter" color="white" />}>Filters</Button>
        <Button color="secondary" onClick={toggleExpand}>
          <Icon icon={expanded ? 'minimize' : 'focus'} />
        </Button>
      </Stack>
    </Stack>
  );
};

export default Header;