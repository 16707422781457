import React from 'react';
import {Box} from "@mui/material";
import {Helmet} from "react-helmet-async";
import styled from "@emotion/styled";

const Wrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: '20px 20px 0',
  minHeight: '100%',
  position: 'relative',
}))

const Page = ({ children, title }) => {
  return (
    <Wrapper>
      {title && <Helmet title={title} />}
      {children}
    </Wrapper>
  );
};

export default Page;
